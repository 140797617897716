$searchIcon: #f0774a;
$blackColor: #011118;
$themeColor: #F26E4C;

.report_title{
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #ffffff;
  padding: 10px 15px;
  font-size: 18px;
  font-weight: 600;
  // background: #001119;
}
.report_body{
  // background: #022735;
  color: #ffffff;
  font-weight: 600;
  font-size: 18px;
  .delete_content{
    width: 100%;
    padding: 10px 20px;
  }
  .delete_footer{
    display: flex;
    justify-content: flex-end;
    padding:10px 20px;
    .cancel_button{
      margin: 0 10px;
      padding: 5px 10px;
      background: rgba(255, 255, 255, 0.3);
      border-radius: 5px;
      cursor: pointer;
    }
    .delete_button{
      padding:5px 10px;
      background: $themeColor;
      border-radius: 5px;
      margin: 0 10px;
      cursor: pointer;
    }
    .delete_button:hover{
      color: rgba(255, 0, 0, 0.9);
    }
  }
  .report_item{
    // border-bottom: 1px solid #213944;
    width: 100%;
    text-align: center;
    padding: 10px 20px;
  }
  .report_item:hover{
    // background: rgba(255, 255, 255, 0.27);
    cursor: pointer;
  }
}
