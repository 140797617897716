$searchIcon: #f0774a;
$blackColor: #011118;
$themeColor: #F26E4C;
$yellowColor: rgba(233, 187, 45, 0.79);

.screenShots_page {
  min-height: 1200px;
  background-size: 100%;
  //font-family:  Montserrat, Roboto, Helvetica, 'Hiragino Sans GB', 'Microsoft Yahei', '微软雅黑', Arial, sans-serif;
  div[class="ant-upload ant-upload-select ant-upload-select-text"]{
    display: inline !important;
    color:#ffffff;
  }
  div[class="ant-image-preview-mask"] {
    background-color: rgba(0, 0, 0, 0.9) !important;
  }
  div[class="ant-modal-content"] {
    border-radius: 8px !important;
  }
  div[class="ant-modal-wrap"] {
    top: -20px !important;
  }
  input{
    color: #fff !important;
  }
  .screenShots {
    width: 1180px;

    margin: 0 auto;
    //    height: 1000px;
    position: relative;
    // background-color: rgba(1, 17, 24, 0.4);

    .screenShots_wrapper {
      width: 100%;
      min-height: 821px;
      // padding: 30px 70px 47px 70px;

      .notice_wrapper {
        width: 100%;
        //height: 270px;
        color: #F488B9;
        font-size: 18px;
        font-family: Montserrat-Medium, Montserrat;
        font-weight: 600;
        line-height: 21px;
        //padding: 22px 22px;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        .left_content {
          width: 100%;
          .label_sty {
            font-size: 18px;
            font-weight: bold;
            color: #F488B9;
            display: flex;
            align-items: center;
          }
          .notice_title {

          }
          .notice_item_wrapper {
            color: #F488B9;
            font-size: 18px;
            font-family: Montserrat-Medium, Montserrat;
            .notice_item {
              margin-left: 10px;
            }
          }
          .notice_item_hit {
            color: #F488B9;
            font-size: 18px;
            font-family: Montserrat-Medium, Montserrat;
            margin-top: .22rem;
          }
        }
        .right_content {
          width: 30%;
          display: flex;
          justify-content: center;
          .apple_download {
            width: 150px;
            height: 41px;
            background: url("../../imgs/home/apple_download.png") center no-repeat;
            background-size: contain;
            cursor: pointer;
          }
          .android_download {
            width: 150px;
            height: 40px;
            background: url("../../imgs/home/android_download.svg") center no-repeat;
            background-size: contain;
            cursor: pointer;
          }
        }
      }

      .tag_list_wrapper {
        width: 100%;
        height: 120px;
        margin-bottom: 0px;
        margin-top: 20px;
        position: relative;
        display: flex;
        align-items: center;
        .tag_list {
          width: 100%;
          height: 100%;
          //display: flex;
          display: -webkit-box;
          overflow:hidden;
          overflow-x: scroll;
          //padding: 0 130px 0 50px;
          //overflow-y: hidden;
          padding: 0 130px 0 50px;
          white-space: nowrap;
          -webkit-overflow-scrolling: touch;
          .tag_item_all {
            width: 120px;
            height: 120px;
            border: 3px solid #9A9EA0;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #9A9EA0;
            font-size: 24px;
            font-weight: 500;
            transition: all .3s;
            margin-right: 5px;
            cursor: pointer;
            user-select: none;
          }
          .tag_item {
            width: 120px;
            height: 120px;
            overflow: hidden;
            position: relative;
            margin: 0 5px;
            cursor: pointer;
            transition: all .1s;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            .model_name_box {
              position: absolute;
              bottom: 0;
              width: 100%;
              height: 30px;
              background-color: rgba(0, 0, 0, 0.6);
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: center;
              line-height: 30px;
              color: #FFFFFF;
              font-size: 13px;
              font-weight: 600;
            }
          }

          .tag_item_active {
            border: 3px solid #F488B9;
            color: #F488B9;
          }
        }
        .left_icon {
          position: absolute;
          left: 0;
          background: url("../../imgs/discover/arrow-right-bold.png") center no-repeat;
          background-size: contain;
          transform: rotate(180deg);
          width: 40px;
          height: 40px;
          cursor: pointer;
        }
        .right_icon {
          position: absolute;
          right: 0;
          background: url("../../imgs/discover/arrow-right-bold.png") center no-repeat;
          background-size: contain;
          width: 40px;
          height: 40px;
          cursor: pointer;
        }
      }

      .screenShots_header {
        background: url("../../imgs/gamieZone-tabbar.png") center;
        background-size: cover;
        border-radius: 10px;
        height: 160px;
        padding: 10px 25px 10px 25px;
        margin: 30px 0px 60px;
        -webkit-user-drag: none;
        .title {
          width:100%;
          font-size: 21px;
          display: flex;
          color: #ffffff;
          align-self: center;
          cursor: pointer;
          font-weight: 800;
          font-family:  Montserrat, Roboto, Helvetica, 'Hiragino Sans GB', 'Microsoft Yahei', '微软雅黑', Arial, sans-serif;
          // font-family: Gats-Regular, Gats;
          .line {
            width: 5px;
            height: 25px;
            align-self: center;
            margin-right: 15px;
            background: $themeColor;
            border-radius: 25px;
          }
        }

        .header_div{
          width: 100%;
          display: flex;
          .tabBar_div{
            width: 100%;
            display: flex;
            .tabBar {
              width:100%;
              align-self: center;
              padding: 15px 10px;
              .img_div {
                width: 190.66px;
                overflow: hidden;
                border-radius: 7px;
                .img {
                  border-radius: 7px;
                  width: 100%;
                  height: 80px;
                  object-fit: cover;
                  -webkit-user-drag: none;
                  transition: all 0.2s;
                  cursor: pointer;
                  -webkit-user-drag: none;
                }

                .img_mobile {
                  border-radius: 7px;
                  display: none;
                  width: 100%;
                  height: 80px;
                  object-fit: cover;
                  -webkit-user-drag: none;
                  transition: all 0.2s;
                  -webkit-user-drag: none;
                }

                .img_fail_m{
                  border-radius: 7px;
                  width: 100%;
                  height: 80px;
                  display: none;
                  object-fit: cover;
                  -webkit-user-drag: none;
                  transition: all 0.2s;
                  -webkit-user-drag: none;
                  -webkit-filter: grayscale(100%);
                  -moz-filter: grayscale(100%);
                  -o-filter: grayscale(100%);
                  filter: grayscale(100%);
                }

                .img_fail{
                  border-radius: 7px;
                  width: 100%;
                  height: 80px;
                  object-fit: cover;
                  -webkit-user-drag: none;
                  transition: all 0.2s;
                  -webkit-user-drag: none;
                  -webkit-filter: grayscale(100%);
                  -moz-filter: grayscale(100%);
                  -o-filter: grayscale(100%);
                  filter: grayscale(100%);
                  cursor: pointer;
                }

                .img:hover {
                  border-radius: 7px;
                  transform: scale(1.3);
                }
                .img_fail:hover {
                  border-radius: 7px;
                  transform: scale(1.3);
                }
              }
              .game_name{
                display: none;
              }
            }
            .skeletonBar {
              width: 100%;
              height: 80px;
              border-radius: 7px;
            }
          }
          .left_icon{
            background: url("../../imgs/zone-left-icon.png") center;
            width: 25px;
            height: 44px;
            background-size: cover;
            margin-top: 30px;
            cursor: pointer;
          }
          .right_icon{
            background: url("../../imgs/zone-right-icon.png") center;
            width: 25px;
            height: 44px;
            background-size: cover;
            margin-top: 30px;
            cursor: pointer;
          }
        }
      }

      .topic_title{
        width:100%;
        font-size: 22px;
        display: flex;
        color: #ffffff;
        align-self: center;
        cursor: pointer;
        font-family:  Montserrat, Roboto, Helvetica, 'Hiragino Sans GB', 'Microsoft Yahei', '微软雅黑', Arial, sans-serif;
        font-weight: 700;
        margin-top: 20px;
      }

      .recommend_creator_box {
        width: 100%;
        position: relative;
        .recommend_creator_wrap{
          display: -webkit-box;
          font-size: 16px;
          color: #ffffff;
          width: 100%;
          margin-top: 10px;
          overflow: hidden;
          overflow-x: scroll;
          .creator_item_box {
            width: 19%;
            margin-right: 1%;
            border-radius: 7px;
            background-size: cover;
            overflow: hidden;
            .creator_item{
              display: flex;
              cursor: pointer;
              padding: 10px 10px;
              width: 100%;
              height: 110px;
              align-items: center;
              background-color: rgba(0, 0, 0, 0.4);
              backdrop-filter: blur(5px);
              -webkit-backdrop-filter: blur(5px);
              //background-color: #132025;
              .item_avatar{
                width: 60px;
                height: 60px;
                background-color: #bbbbbb;
                border-radius: 50%;
                object-fit: cover;
              }
              .item_userinfo{
                margin-left: 10px;
                width: calc(100% - 10px - 70px);
                display: flex;
                height: 100%;
                flex-direction: column;
                justify-content: space-between;
                .item_username{
                  font-weight: 600;
                  overflow:hidden; //溢出的部分隐藏；
                  white-space: nowrap; //文本强制不换行；
                  text-overflow:ellipsis; //文本溢出显示省略号；
                }
                .item_bio{
                  font-size: 13px;
                  word-break: break-all;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2; /* 这里是超出几行省略 */
                  overflow: hidden;
                }
                .item_post {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  span {
                    font-size: 12px;
                  }
                }
              }
            }
          }

        }
        .left_icon {
          position: absolute;
          left: -40px;
          top: 37%;
          background: url("../../imgs/discover/arrow-right-bold.png") center no-repeat;
          background-size: contain;
          transform: rotate(180deg);
          width: 40px;
          height: 40px;
          cursor: pointer;
        }
        .right_icon {
          position: absolute;
          right: -40px;
          top: 37%;
          background: url("../../imgs/discover/arrow-right-bold.png") center no-repeat;
          background-size: contain;
          width: 40px;
          height: 40px;
          cursor: pointer;
        }
      }
      .recommend_post_wrap {
        width: 100%;
        position: relative;
        ul {
          display: flex;
          flex-flow: column wrap;
          list-style-type: none;
          width: 100%;
          aspect-ratio: 1.89;
          overflow-x: scroll;
          li {
            width: 19%;
            margin-top: 1.23%;
            margin-right: 1%;
            cursor: pointer;
            .recommend_post_item {
              width: 100%;
              border: 1px solid #000000;
              border-radius: 12px;
              transition: all .4s;
              overflow: hidden;
              .image_box {
                width: 100%;
                aspect-ratio: 1;
                overflow: hidden;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  object-position: top left;
                  transition: all .3s;
                }
              }
              .tab_wrap {
                padding: 0 6px;
                width: 100%;
                height: 60px;
                background: #0F1516;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .left_wrap {
                  display: flex;
                  align-items: center;
                  .header_box {
                    width: 38px;
                    height: 38px;
                    border-radius: 50%;
                    overflow: hidden;
                    img {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                    }
                  }
                  .info_wrap {
                    color: #ffffff;
                    margin-left: 5px;
                    .post_title {
                      width: 1.2rem;
                      font-size: 16px;
                      font-weight: 600;
                      line-height: 17px;
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    }
                    .user_name {
                      width: 1.2rem;
                      font-size: 12px;
                      line-height: 13px;
                      margin-top: 4px;
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      font-weight: 400;
                    }
                  }
                }
                .right_wrap {
                  .send_tip_icon {
                    width: 22px;
                    height: 22px;
                    background: url("../../imgs/discover/tips_Icon.png") center no-repeat;
                    background-size: contain;
                    cursor: pointer;
                  }
                  .info_icon {
                    width: 20px;
                    height: 20px;
                    background: url("../../imgs/generate/info_icon.png") center no-repeat;
                    background-size: contain;
                    cursor: pointer;
                    margin-top: 5px;
                  }
                }
              }
              &:hover {
                border: 1px solid #F488B9;
                .image_box {
                  img {
                    transform: scale(1.2);
                  }
                }
              }
            }
          }
        }
        .then_five {
          flex-flow: row wrap;
          aspect-ratio: 0;
        }
        .more_five {
          flex-flow: row wrap;
          aspect-ratio: 1.89;
        }
        .more_ten {
          flex-flow: column wrap;
          aspect-ratio: 1.89;
        }
        .left_icon {
          position: absolute;
          left: -40px;
          top: 47%;
          background: url("../../imgs/discover/arrow-right-bold.png") center no-repeat;
          background-size: contain;
          transform: rotate(180deg);
          width: 40px;
          height: 40px;
          cursor: pointer;
        }
        .right_icon {
          position: absolute;
          right: -40px;
          top: 47%;
          background: url("../../imgs/discover/arrow-right-bold.png") center no-repeat;
          background-size: contain;
          width: 40px;
          height: 40px;
          cursor: pointer;
        }
      }

      .topic{
        // padding:20px 5px 0 5px;
        .title {
          width:100%;
          font-size: 21px;
          display: flex;
          color: #ffffff;
          align-self: center;
          cursor: pointer;
          font-family:  Montserrat, Roboto, Helvetica, 'Hiragino Sans GB', 'Microsoft Yahei', '微软雅黑', Arial, sans-serif;
          font-weight: 800;
          margin-top: 0px;
          margin-bottom: 14px;
          .line {
            width: 5px;
            height: 25px;
            align-self: center;
            margin-right: 15px;
            background: $themeColor;
            border-radius: 25px;
          }
        }
        .topic_list{
          width: 100%;
          display:flex;
          flex-wrap: wrap;
          .topic_list_item{
            width: calc(33.33% - 20.7px);
            margin: 10px 20px 10px 10px;
            background: #1a282f85;
            border-radius: 8px;
            display: flex;
            padding: 10px 10px;
            cursor: pointer;
            color: #FFFFFF;
            transition: all .2s ease-in-out;
            .topic_details{
              padding: 5px 13px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .topic_title{
                font-size: 17px;
                line-height: 21px;
                display: flex;
                font-weight: 600;
                font-family:  Montserrat, Helvetica, 'Hiragino Sans GB', 'Microsoft Yahei', '微软雅黑', Arial, sans-serif !important;
                .hot_icon{
                  background: url("../../imgs/hot_icon.svg") no-repeat;
                  width: 35px;
                  height: 20px;
                  background-size: cover;
                  margin-left: 5px;
                }
              }
              span{
                margin: 0 10px 0 0;
                font-size: 13px;
                color: #8B9499;
                font-weight: 600;

              }
            }
            .topic_img{
              width: 91px;
              height: 70px;
              object-fit: cover;
              border-radius: 8px;
            }
          }
          .topic_list_item:nth-child(3n+1) {
            margin-left: 0;
          }
          .topic_list_item:nth-child(3n+3) {
            margin-right: 0;
          }
          .topic_list_item:hover{
            background:#2e475478;
          }
        }
      }

      .middle{
        position: relative;
        .body_filter{
          display: flex;
          justify-content: space-between;
          width: 200px;
          margin: 40px auto -24px auto;
          font-weight: bold;
          color:#ffffff;
          cursor: pointer;
          font-size: 15px;
          font-family:  Montserrat, Roboto, Helvetica, 'Hiragino Sans GB', 'Microsoft Yahei', '微软雅黑', Arial, sans-serif;
          .checked{
            color: $yellowColor;
          }
        }
        .down_list{
          position: absolute;
          z-index: 100;
          font-weight: 500;
          right: 5px;
          justify-content: flex-end;
          text-align: center;
          .list_content{
            background: #1d2e38;
            display: none;
          }
        }
        .down_list:hover{
          .list_content{
            margin-top: 5px;
            text-align: left;
            padding: 5px 0;
            height: 90px;
            background: #111F2A;
            display: flex;
            border-radius: 10px;
            flex-direction: column;
            justify-content: space-around;
            align-items: start;
            .list_item{
              width: 100%;
              font-weight: 400;
              padding: 5px 7px;
              font-size:13px;
              color: #ffffff;
            }
            .list_item:hover{
              color: $yellowColor;
              background: rgba(124, 135, 141, 0.31);
            }
          }
        }
      }

      .select_content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 20px 0 15px 0;
        height: 63px;

        :global{
          .ant-select:not(.ant-select-customize-input) .ant-select-selector{
            background: #FFFFFF00;
            color: #FFFFFF;
            border: none;
          }
          .ant-select-arrow{
            color: #FFFFFF;
          }
        }

        .scroll_wrap {
          .title_wrapper {
            color: #fff;
            font-size: 21px;
            font-weight: 700;
            display: flex;
            align-items: center;
            >div {
              margin-right: 50px;
              cursor: pointer;
            }
            .active_tab {
              color: #F26E4C;
            }

          }

        }
        .select_wrap{
          color: #FFFFFF;
          font-weight: 500;
          height: 43px;
          background: #132024;
          border-radius: 6px;
          font-family:  Montserrat, Roboto, Helvetica, 'Hiragino Sans GB', 'Microsoft Yahei', '微软雅黑', Arial, sans-serif !important;
          font-size: 17px;
          padding: 0 12px;
          align-items: center;
          margin-bottom: 20px;
          .sort_by{
            margin-right: 12px;
            font-weight: 600;
            color: #A5AAAB;
          }
          .select_target{
            height: 33px;
            line-height: 33px;
            background: #000000;
            border-radius: 6px;
            font-weight: 500;
            color: #FFFFFF;
            font-size: 15px;
            width: 195px;
            display: flex;
            justify-content: space-between;
            padding: 0 15px;
            align-items: center;
            cursor: pointer;
            .select_arrow{
              background: url("../../imgs/select_arrow.png") no-repeat;
              height: 7px;
              width: 13px;
              background-size: contain;
              transform: rotateX(180deg);
              transition: all .3s ease-in-out;
            }

            &:hover{
              .select_arrow{
                transform: rotateX(360deg);
              }
            }
          }
        }
        .input_wrap {
          width: 320px;
          margin-bottom: 20px;
          padding: 5px 12px;
          background: #132024;
          border-radius: 6px;
          .input_sty {
            border: unset;
            background: #000000 !important;
            font-size: 15px;
            font-weight: 600;
          }
        }
        .right_wrapper {
          display: flex;
          .create_post_btn {
            .icon {
              width: 20px;
              height: 20px;
              background: url("../../imgs/createPost/add_icon_color.png") center no-repeat;
              background-size: contain;
              margin-right: 8px;
            }
            width: 230px;
            height: 45px;
            color: #F488B9;
            margin-right: 10px;
            border: 1px solid #F488B9;
            //background: linear-gradient(-60deg, #F488B9, #fb9ec8, #F488B9);
            border-radius: 8px;
            font-size: 20px;
            font-weight: 600;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            box-shadow: 0 0 5px #e969d8;
          }
          .generate_btn {
            .icon {
              width: 25px;
              height: 25px;
              background: url("../../imgs/home/magic_icon.png") center no-repeat;
              background-size: contain;
              margin-right: 8px;
            }
            .chat_icon{
              width: 25px;
              height: 25px;
              background: url("../../imgs/character/chat.svg") center no-repeat;
              background-size: contain;
              margin-right: 8px;
            }
            min-width: 180px;
            padding: 0 15px;
            height: 45px;
            color: #590B57;
            background: linear-gradient(-60deg, #F488B9, #fb9ec8, #F488B9);
            border-radius: 8px;
            font-size: 20px;
            font-weight: 600;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            cursor: pointer;
            box-shadow: 0 0 5px #e969d8;
          }

          .margin_right_0{
            margin-right: 0px;
          }
        }
      }

      .space_between{
        .mobile_select{
          display: none;
        }
      }

      .screenShots_content {
        width: 100%;
        border-radius: 10px;
        .screenShots_content_masonry {
          width: 100%;
          > div {
            width: 33.33%;

          }
          .skeleton {
            width: 300px;
            height: 300px;
          }
          .item {
            background: #111F2A;
            border-radius: 10px;
            width: 290px;
            display: flex;
            min-height: 340px;
            flex-direction: column;
            position: relative;
            color: #ffffff;
            margin: 15px 15px;
            display: flex;
            flex-direction: column;
            .week_best {
              background: url("../../imgs/screent-label-weekbest.png") no-repeat;
              position: absolute;
              background-size: cover;
              width: 100px;
              height: 30px;
              top: 7px;
              left: -7px;
              color: #fff;
              text-align: center;
              line-height: 25px;
              font-weight: bold;
            }

            .hot_deal {
              background: url("../../imgs/screen-label-hotdeal.png") no-repeat;
              position: absolute;
              background-size: cover;
              width: 97px;
              height: 32px;
              top: 7px;
              left: -7px;
              color: #fff;
              text-align: center;
              font-weight: bold;
              line-height: 25px;
            }

            .item_img_div {
              width: 100%;
              min-height: 300px;
              border-top-left-radius: 10px;
              border-top-right-radius: 10px;
              overflow: hidden;
            }

            .item_img {
              width: 100%;
              min-height: 300px;
              border-top-left-radius: 10px;
              border-top-right-radius: 10px;
              object-fit: cover;
              -webkit-user-drag: none;
              transition: all 0.2s;
            }
          ;

            .item_img:hover {
              cursor: zoom-in;
              transform: scale(1.2);
            }
            .userAvatar {
              position: absolute;
              border:  1px solid #ffffff;
              border-radius: 50%;
              z-index: 1;
              left: 50%;
              bottom: 4px;
              transform: translateX(-50%);
              cursor: pointer;
            }
            .item_userInfo {
              width: 100%;
              display: flex;
              justify-content: space-between;
              background-color: #111F2A;
              min-height: 40px;
              border-radius: 0 0 10px 10px;
              .share_div {
                // margin-top: 35px;
                margin-left: 5px;
                display: flex;
                justify-content: space-evenly;
                text-align: center;
                align-items: center;
                align-content: center;
                width: 20%;
                font-size: 11px;
                color: #ffffff;
                font-weight: normal
              }
              .username {
                font-size: 13px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                cursor: pointer;
                padding-top: 10px;
              }
              .star_div {
                // margin-top: 20px;
                display: flex;
                width: 20%;
                font-size: 11px;
                justify-content: space-evenly;
                align-items: center;
                text-align: center;
                // line-height: 47px;
                color: #ffffff;
              }

              .shareIcon {
                background: url("../../imgs/screen-shot-see.png") no-repeat;
                width: 22px;
                height: 14px;
                background-size: cover;
                // margin-left: 5px;
                margin-top: 1px;
              }

              .giveStar {
                background: url("../../imgs/fire-stop-3x.png") no-repeat;
                width: 24px;
                height: 24px;
                // margin-top: 10px;
                margin-right: 5px;
                cursor: pointer;
                background-size: cover;
              }

              .star {
                background: url("../../imgs/fire-start.png") no-repeat;
                width: 23px;
                height: 23px;
                background-size: cover;
                // margin-top: 10px;
                margin-right: 5px;
                cursor: pointer;
              }
            }
            .item_user {
              position: absolute;
              display: flex;
              justify-content: space-between;
              padding: 20px 7px;
              width: 100%;
              bottom: 40px;
              height: 40px;

              .share_div {
                margin-top: 35px;
                margin-left: 5px;
                display: flex;
                justify-content: space-evenly;
                text-align: center;
                align-content: center;
                width: 20%;
                font-size: 11px;
                color: #ffffff;
                font-weight: normal
              }

              .username_div {
                text-align: center;
                width: 60%;
                .headPic_div {
                  cursor: pointer;
                }

                .username {
                  font-size: 13px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  cursor: pointer;
                }
              }

              .star_div {
                margin-top: 20px;
                display: flex;
                width: 20%;
                font-size: 11px;
                justify-content: space-evenly;
                text-align: center;
                line-height: 47px;
                color: #ffffff;
              }

              .shareIcon {
                background: url("../../imgs/screen-shot-see.png") no-repeat;
                width: 22px;
                height: 14px;
                background-size: cover;
                // margin-left: 5px;
                margin-top: 1px;
              }

              .giveStar {
                background: url("../../imgs/fire-stop-3x.png") no-repeat;
                width: 24px;
                height: 24px;
                margin-top: 10px;
                margin-right: 5px;
                cursor: pointer;
                background-size: cover;
              }

              .star {
                background: url("../../imgs/fire-start.png") no-repeat;
                width: 23px;
                height: 23px;
                background-size: cover;
                margin-top: 10px;
                margin-right: 5px;
                cursor: pointer;
              }
            }
          ;

            .item_fire {
              position: absolute;
              bottom: 55px;
              left: 15px;
              color: #fff;
              font-size: 11px;
              height: 30px;
              line-height: 30px;
              display: flex;
              align-content: center;

              .starFire {
                background: url("../../imgs/Srceenfire.png") center no-repeat;
                width: 20px;
                height: 20px;
                background-size: cover;
                margin-right: 5px;
              }
            }
          ;

            .item_video_play {
              background: url("../../imgs/videoPlay.png") center;
              width: 29.5px;
              height: 29px;
              right:5px;
              top: 5px;
              background-size: cover;
              position: absolute;
            }

            .item_white {
              height: 40px;
            }

            //banner css
            .item_img_div_banner {
              border-radius: 10px;
              min-height: 300px;
              overflow: hidden;
              position: relative;
            }

            .item_img_banner {
              width: 100%;
              border-radius: 10px;
              height: 300px;
              object-fit: cover;
              -webkit-user-drag: none;
            }
          ;

            .item_img_banner_title_div {
              width: 100%;
              height: 70px;
              border-radius: 7px;
              position: absolute;
              bottom: 0;
            }

            .item_img_banner_title {
              width: 100%;
              height: 60px;
              border-radius: 10px;
              text-align: center;
              position: absolute;
              font-size: 15px;
              font-weight: bold;
              color: #ffffff;
              bottom: 0px;
            }

            .item_img_active_button {
              background: #F26E4C;
              margin: 7px 10px;
              padding: 10px;
              border-radius: 7px;
              text-align: center;
              font-weight: 600;
              color: #ffffff;

              .upload_icon {
                background: url("../../imgs/upload-icon.png") no-repeat;
                background-size: cover;
                width: 11px;
                height: 11px;
                display: inline-block;
                margin-left: 5px;
              }
            }
          }

          .item_banner {
            background: #111F2A;
            border-radius: 10px;
            width: 300px;
            display: flex;
            flex-direction: column;
            position: relative;
            margin: 7px 10px;
            //banner css
            .item_img_div_banner {
              border-radius: 10px;
              overflow: hidden;
              min-height: 300px;
              position: relative;
            }

            .item_img_banner {
              width: 100%;
              border-top-right-radius: 7px;
              border-top-left-radius: 7px;
              height: 300px;
              object-fit: cover;
              -webkit-user-drag: none;
            }

            .item_img_banner_title_div {
              width: 100%;
              height: 70px;
              position: absolute;
              bottom: 0px;
            }

            .item_img_banner_title {
              width: 100%;
              border-radius: 10px;
              text-align: center;
              position: absolute;
              font-size: 15px;
              font-weight: bold;
              color: #ffffff;
              bottom:7px;
            }
            .item_white{
              border-bottom-right-radius: 10px;
              border-bottom-left-radius: 10px;
              height: 70px;
            }
            .item_img_active_button {
              background: #F26E4C;
              margin: 7px 10px;
              padding: 10px;
              border-radius: 7px;
              text-align: center;
              font-weight: 600;
              color: #ffffff;
              cursor: pointer;
              .upload_icon {
                background: url("../../imgs/upload-icon.png") no-repeat;
                background-size: cover;
                width: 11px;
                height: 11px;
                display: inline-block;
                margin-left: 5px;
              }
            }
          }
        }
      }

    }
    .new_upLoad_post{
      position: fixed;
      bottom: 90px;
      background: #1d2e38;
      border: 0.3px solid rgba(11, 12, 14, 0.30);
      right: 140px;
      margin: 0 auto;
      border-radius:30px;
      width: 70px;
      padding: 10px 10px;
      .post_icon{
        width:50px;
        height: 50px;
        border-radius: 50%;
        background: radial-gradient(circle at 50px 50px,red,yellow);
        font-size: 50px;
        line-height: 50px;
        cursor: pointer;
        font-weight: 700;
        color: #ffffff;
        text-align: center;
      }
      .upToTop_icon{
        margin-top: 10px;
        background: url("../../imgs/upToTop.png") no-repeat;
        width:50px;
        height: 50px;
        background-size: contain;
      }
    }
  }

  .screenShots_mobile {
    display: none;
    width: 100%;
    margin: 45px auto;
    //    height: 1000px;
    position: relative;
    background-color: rgba(1, 17, 24, 0.4);

    .screenShots_wrapper {
      width: 100%;
      min-height: 821px;
      margin-top: 10px;
      padding: 34px 0px 47px 0px;
      .notice_wrapper {
        margin-bottom: .32rem;
        width: 100%;
        //height: 270px;
        background: #132025;
        color: #F488B9;
        font-size: .25rem;
        font-family: Montserrat-Medium, Montserrat;
        font-weight: 600;
        line-height: 21px;
        //padding: 22px 22px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .label_sty {
          font-size: .25rem;
          font-weight: bold;
          color: #F488B9;
          display: flex;
          align-items: center;
        }
        .notice_title {

        }
        .notice_item_wrapper {
          color: #F488B9;
          font-size: .25rem;
          font-family: Montserrat-Medium, Montserrat;
          .notice_item {
            //margin-left: 10px;
          }
        }
        .notice_item_hit {
          color: #F488B9;
          font-size: .25rem;
          font-family: Montserrat-Medium, Montserrat;
          margin-top: .1rem;
        }

      }
      .select_content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        //margin: 40px 0 15px 0;
        height: .9rem;
      }
      .tag_list_wrapper {
        width: 100%;
        height: 1.9rem;
        margin-bottom: .22rem;
        position: relative;
        display: flex;
        align-items: center;
        .tag_list {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          right: 0;
          //display: flex;
          display: -webkit-box;
          overflow:hidden;
          overflow-x: scroll;
          padding: unset;
          //overflow-y: hidden;
          white-space: nowrap;
          .tag_item_all {
            width: 1.9rem;
            height: 1.9rem;
            border: 3px solid #9A9EA0;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #9A9EA0;
            font-size: 24px;
            font-weight: 500;
            transition: all .3s;
            margin-right: 5px;
            cursor: pointer;
          }
          .tag_item {
            width: 1.9rem;
            height: 1.9rem;
            overflow: hidden;
            position: relative;
            margin: 0 5px;
            cursor: pointer;
            transition: all .1s;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            .model_name_box {
              position: absolute;
              bottom: 0;
              width: 100%;
              height: .5rem;
              background-color: rgba(0, 0, 0, 0.6);
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: center;
              line-height: .5rem;
              color: #FFFFFF;
              font-size: .22rem;
              font-weight: 600;
            }
          }

          .tag_item_active {
            border: 3px solid #F488B9;
            color: #F488B9;
          }
        }
        .left_icon {
          position: absolute;
          left: 0;
          background: url("../../imgs/discover/arrow-right-bold.png") center no-repeat;
          background-size: contain;
          transform: rotate(180deg);
          width: 40px;
          height: 40px;
          cursor: pointer;
        }
        .right_icon {
          position: absolute;
          right: 0;
          background: url("../../imgs/discover/arrow-right-bold.png") center no-repeat;
          background-size: contain;
          width: 40px;
          height: 40px;
          cursor: pointer;
        }
      }

      .topic_title{
        width:100%;
        font-size: 17px;
        display: flex;
        color: #ffffff;
        align-self: center;
        cursor: pointer;
        font-family:  Montserrat, Roboto, Helvetica, 'Hiragino Sans GB', 'Microsoft Yahei', '微软雅黑', Arial, sans-serif;
        font-weight: 700;
        margin-top: 20px;
        margin-bottom: 10px;
      }

      .recommend_creator_wrap{
        width: calc(100vw - .4rem);
        display: -webkit-box;
        overflow-x: scroll;
        font-size: 16px;
        color: #ffffff;
        margin-top: 10px;
        white-space: nowrap;
        .creator_item_box {
          width: 5rem;
          background-size: cover;
          overflow: hidden;
          border-radius: 7px;
          margin-right: .22rem;
          .creator_item{
            width: 5rem;
            display: flex;
            padding: 10px 10px;
            background-color: rgba(0, 0, 0, 0.4);
            backdrop-filter: blur(5px);
            -webkit-backdrop-filter: blur(5px);
            .item_avatar{
              width: 70px;
              height: 70px;
              background-color: #bbbbbb;
              border-radius: 50%;
              object-fit: cover;
            }
            .item_userinfo{
              margin-left: 10px;
              width: 2.8rem;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .item_username{
                font-weight: 600;
                font-size: 15px;
                overflow:hidden; //溢出的部分隐藏；
                white-space: nowrap; //文本强制不换行；
                text-overflow:ellipsis; //文本溢出显示省略号；
              }
              .item_bio{
                font-size: 13px;
                word-break: normal;
                text-overflow: ellipsis;
                overflow: hidden;
              }
              .item_post {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                span {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }

      .recommend_post_wrap {
        width: 100%;
        ul {
          display: flex;
          flex-flow: column wrap;
          list-style-type: none;
          width: 100%;
          aspect-ratio: .92;
          overflow-x: scroll;
          li {
            width: 40%;
            margin-top: 1.23%;
            margin-right: 1.23%;
            cursor: pointer;
            transition: all .4s;
            border-radius: .12rem;
            overflow: hidden;
            .recommend_post_item {
              width: 100%;
              .image_box {
                width: 100%;
                aspect-ratio: 1;
                overflow: hidden;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  object-position: top left;
                  transition: all .3s;
                }
              }
              .tab_wrap {
                padding: 0 .1rem;
                width: 100%;
                height: .9rem;
                background: #0F1516;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .left_wrap {
                  display: flex;
                  align-items: center;
                  .header_box {
                    width: .55rem;
                    height: .55rem;
                    border-radius: 50%;
                    overflow: hidden;
                    img {
                      width: 100%;
                      height: 100%;
                    }
                  }
                  .info_wrap {
                    color: #ffffff;
                    margin-left: 5px;
                    .post_title {
                      width: 1.6rem;
                      font-size: .28rem;
                      font-weight: 600;
                      line-height: .29rem;
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    }
                    .user_name {
                      width: 1.6rem;
                      font-size: .19rem;
                      line-height: .20rem;
                      margin-top: .08rem;
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      font-weight: 400;
                    }
                  }
                }
                .right_wrap {
                  .send_tip_icon {
                    width: .3rem;
                    height: .3rem;
                    background: url("../../imgs/discover/tips_Icon.png") center no-repeat;
                    background-size: contain;
                    cursor: pointer;
                  }
                  .info_icon {
                    width: .3rem;
                    height: .3rem;
                    background: url("../../imgs/generate/info_icon.png") center no-repeat;
                    background-size: contain;
                    cursor: pointer;
                    margin-top: 5px;
                  }
                }
              }
            }
          }
        }
      }

      .post_tab_wrap {
        width: 100%;
        margin-top: .32rem;
        :global {
          .ant-tabs {

          }
          .ant-tabs-tab {
            font-size: 19px;
            font-weight: 600;
            color: #ffffff;
            padding: unset;
            transition: all .3s;
          }
          .ant-tabs-ink-bar {
            background: #F488B9;
          }
          .ant-tabs-nav-wrap {
            margin-bottom: .17rem;
          }
          .ant-tabs-tab-btn {
            margin-bottom: .1rem;
          }
          .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab, .ant-tabs-right > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab, .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab, .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
            //margin: 8px 0 0 0;
          }
          .ant-tabs-top > .ant-tabs-nav, .ant-tabs-bottom > .ant-tabs-nav, .ant-tabs-top > div > .ant-tabs-nav, .ant-tabs-bottom > div > .ant-tabs-nav {
            margin: unset;
          }
          .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
            color: #F488B9 ;
            //font-size: 22px;
          }
          .ant-tabs-tab-btn:focus, .ant-tabs-tab-remove:focus, .ant-tabs-tab-btn:active, .ant-tabs-tab-remove:active {
            color: #F488B9 ;
            //font-size: 22px;
          }
          .ant-tabs-tab:hover {
            color: #F488B9 ;
            //font-size: 22px;
          }
        }
      }

      .title {
        width: 100%;
        color: white;
        font-size: 17px;
        font-weight: bold;
        display: flex;
        align-self: center;

        .line {
          width: 4px;
          height: 18px;
          align-self: center;
          margin-left: 2.5vw;
          margin-right: 11px;
          background: $themeColor;
          border-radius: 25px;
        }
      }

      .screenShots_header {
        // width: 97vw;
        width: 7.1rem;
        display: flex;
        white-space:nowrap;
        overflow-x: scroll;
        margin-top: 10px;
        .tabBar {
          width: 17vw;
          margin:0 4vw;
          .img_div {
            width: 17vw;
            overflow: hidden;
            height: 17vw;
            border-radius: 50%;
            img {
              border-radius: 50%;
              width: 100%;
              height: 17vw;
              object-fit: cover;
              -webkit-user-drag: none;
              transition: all 0.2s;
            }
            img:hover {
              transform: scale(1.1);
            }
          }

        }
        .skeletonBar {
          border-radius: 50%;
          width: 17vw;
          height: 17vw;
          margin:0 4vw;
        }
        .box_mob{
          width: 4vw;
        }
      }

      .middle{
        display: flex;
        justify-content: space-between;
        color: #a0acb2;
        margin-top: 10px;
        padding: 10px 3vw 0px 3vw;
        border-top: 0.5px solid #1d2e38;
        font-weight: bold;
        .checked{
          color: $yellowColor;
        }
        .line{
          width: 1px;
          height: 15px;
          margin-right: 7px;
          margin-top: 3px;
          background: #7C878D;
        }
        .down_list{
          display: flex;
          .right_icon{
            background: url("../../imgs/video_icon.svg") no-repeat;
            width: 13px;
            height: 12.7px;
            margin-left: 5px;
            margin-top: 5px;
            background-size: contain;
            margin-right: 10px;
          }
          .left_icon{
            background: url("../../imgs/post——icon.png") no-repeat;
            width: 14px;
            height: 12px;
            // margin-left: 5px;
            // margin-top: 5px;
            margin: 5px;
            background-size: cover;
          }
        }
      }

      .screenShots_content {
        width: 100%;
        border-radius: 7px;
        padding-bottom: 1.5vw;
        margin-top: 5px;
        .screenShots_content_masonry {
          width: 98vw;
          margin: 0 auto;
          .skeleton {
            width: 47vw;
            border-radius: 7px;
            height: 200px;
          }

          .item {
            background: #111F2A;
            border-radius: 7px;
            width: 46vw;
            margin: 1.5vw;
            min-height: 230px;
            color: #ffffff;
            position: relative;
            .week_best {
              background: url("../../imgs/screent-label-weekbest.png") no-repeat;
              position: absolute;
              background-size: cover;
              width: 81px;
              height: 25px;
              top: 7px;
              left: -5px;
              color: #fff;
              text-align: center;
              line-height: 21px;
              font-size: 12px;
            }
            .hot_deal {
              background: url("../../imgs/screen-label-hotdeal.png") no-repeat;
              position: absolute;
              background-size: cover;
              width: 80px;
              height: 22px;
              top: 7px;
              left: -5px;
              color: #fff;
              text-align: center;
              line-height: 19px;
              font-size: 12px;
            }
            .item_img_div {
              border-top-left-radius: 7px;
              border-top-right-radius: 7px;
              min-height: 200px;
              overflow: hidden;
            }
            .item_img {
              width: 100%;
              border-top-left-radius: 7px;
              border-top-right-radius: 7px;
              object-fit: cover;
              min-height: 200px;
              -webkit-user-drag: none;
              transition: all 0.6s;
            }
            .item_img:hover {
              transform: scale(1.1);
            }

            .item_user {
              position: absolute;
              display: flex;
              justify-content: space-between;
              padding: 20px 7px;
              width: 100%;
              bottom: 35px;
              height: 40px;
              .share_div {
                margin-top: 39px;
                display: flex;
                justify-content: flex-start;
                text-align: center;
                align-content: center;
                width: 30%;
                font-size: 11px;
                color: #ffffff;
                font-weight: normal;
                text-align: center;
                line-height: 13px;
              }

              .username_div {
                text-align: center;
                width: 40%;
                .headPic_div {
                }
                .username {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  font-size: 11px;
                }
              }

              .star_div {
                margin-top: 32px;
                align-content: center;
                font-weight: normal;
                display: flex;
                justify-content: flex-end;
                width: 30%;
                font-size: 11px;
                text-align: center;
                line-height: 27px;
                color: #ffffff;
              }

              .shareIcon {
                background: url("../../imgs/screen-shot-see.png") no-repeat;
                width: 16px;
                height: 11px;
                margin-top: 1px;
                background-size: cover;
                margin-right: 3px;
              }
              .giveStar {
                background: url("../../imgs/fire-stop-3x.png") no-repeat;
                width: 16px;
                height: 16px;
                background-size: cover;
                margin-top: 4px;
                margin-left: 5px;
              }
              .star {
                background: url("../../imgs/fire-start.png") no-repeat;
                width: 16px;
                height: 16px;
                background-size: cover;
                margin-top: 4px;
                margin-left: 5px;
              }
            }
          ;

            .item_fire {
              position: absolute;
              bottom: 55px;
              left: 7px;
              color: #fff;
              font-size: 11px;
              height: 30px;
              line-height: 30px;
              display: flex;
              align-content: center;

              .starFire {
                background: url("../../imgs/Srceenfire.png") center no-repeat;
                width: 15px;
                height: 15px;
                background-size: cover;
                margin-right: 3px;
                margin-top: 5px;
              }
            };

            .item_video_play {
              background: url("../../imgs/videoPlay.png") center;
              width: 29.5px;
              height: 29px;
              right:5px;
              top: 5px;
              background-size: cover;
              position: absolute;
            }

            .item_white {
              height: 30px;
              margin-bottom: 5px;
            }

          }

          .item_banner {
            background: #111F2A;
            border-radius: 7px;
            width: 46vw;
            margin: 1.5vw;
            position: relative;
            //banner css
            .item_img_div_banner {
              border-radius: 7px;
              overflow: hidden;
              height: 46vw;
              position: relative;
            }

            .item_img_banner {
              width: 100%;
              border-radius: 7px;
              height: 46vw;
              object-fit: cover;
              -webkit-user-drag: none;
            }

            .item_img_banner_title_div {
              width: 100%;
              height: 45px;
              border-radius: 7px;
              position: absolute;
              bottom: 0;
            }

            .item_img_banner_title {
              width: 100%;
              height: 35px;
              border-radius: 7px;
              text-align: center;
              position: absolute;
              font-size: 9px;
              font-weight: bold;
              color: #ffffff;
              bottom: 5px;
            }
            .item_white{
              border-bottom-right-radius: 10px;
              border-bottom-left-radius: 10px;
              height: 70px;
            }

            .item_img_active_button {
              background: #F26E4C;
              margin: 7px 10px;
              padding: 5px;
              font-size: 11px;
              border-radius: 5px;
              text-align: center;
              color: #ffffff;
            }
          }
        }
      }

    }
    .new_upLoad_post{
      .post_icon{
      }
    }
  }
  .screenShots_mobile_tagPage{
    margin: .85rem auto 0;
  }
  .dropHeight{}
}


.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);

  .modal-wrapper {
    background: rgba(2, 38, 55, 0.9);
    border: 2px solid rgb(19, 64, 70);
    width: 370px;
    height: 425px;
    border-radius: 7px;
    margin: 200px auto;
    padding: 30px;
    color: white;
    transition: all 0.6s;
    z-index: 1000;
  }

  .title-bar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;

    .share-img {
      width: 95%;
      height: 230px;
      object-fit: cover;
      position: absolute;
      top: -90px;
      border-radius: 7px;
    }

    .back {
      position: absolute;
      right: -20px;
      top: -100px;
      font-weight: bold;
    }
  }

  .share-content-text {
    margin-top: 150px;
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 17px;
  }

  .share-bottom-text {
    margin-top: 20px;
    width: 100%;
    text-align: center;
    color: rgba(255, 255, 255, 0.70);
    font-size: 13px;
  }

  .share-third {
    margin-top: 20px;
    padding: 0px 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .share-copy-button {
    width: 100%;
    background: $themeColor;
    margin: 20px 0px;
    text-align: center;
    padding: 10px 0px;
    font-size: 19px;
    border-radius: 7px;
  }

  .face-book {
    background: url("../../imgs/share_facebook.png");
    background-size: cover;
    width: 40px;
    height: 40px;
  }

  .ins-book {
    background: url("../../imgs/twitter-icon.png");
    background-size: cover;
    width: 40px;
    height: 40px;
  }

  .message-book {
    background: url("../../imgs/share_whatapp.png");
    background-size: cover;
    width: 40px;
    height: 40px;
  }

}

.spin {
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
}

.search_icon{
  background: url("../../imgs/search-icon.png") no-repeat;
  width: 17px;
  height: 17px;
  display: inline-block;
  background-size: cover;
}

.check_item {
  width: 100%;
  height: 30px;
  padding: 5px 14px;
}

.cancel_icon{
  position: absolute;
  top: -14px;
  right: -35px;
  font-size: 25px;
  font-weight: bold;
}

.cancel_icon_mobile{
  position: absolute;
  top: -50px;
  right: 0;
  font-size: 25px;
  font-weight: bold;
}

.check_item:hover {
  background: #24414C;
}

.reward_modal_page {
  background: #0F1517;
  padding: 10px 22px 20px;
  border-radius: 8px;
  .header_wrapper {
    margin-bottom: 22px;
    .title {
      //margin-left: 14px;
      font-weight: bold;
      font-size: 16px;
      color: #FFFFFF;
    }
  }

  .user_info_wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 22px;
    .head_pic {
      .header_img_Avatar {
        width: 46px;
        height: 46px;
        border: 2px solid #F488B9;
        cursor: pointer;
      }
    }
    .user_name {
      margin-left: 14px;
      font-weight: bold;
      font-size: 18px;
      color: #FFFFFF;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
    }
  }

  .input_wra {
    background-color: #132024 !important;
    width: 100%;
    height: 45px;
    line-height: 45px;
    border: unset;
    border-radius: 8px;
    //font-family: Montserrat-SemiBold, Montserrat;
    font-size: 15px;
    font-weight: 600;
    :global {
      .ant-input-number-handler-wrap {
        display: none;
      }
      .ant-input-number-input {

      }
      .ant-input-number-input::-webkit-input-placeholder {
        font-weight: 500;
        color: #9A9EA0;
        font-size: 16px;
        //font-family: Montserrat;
      }
      .ant-input-number-input::-moz-placeholder {
        font-weight: 500;
        color: #9A9EA0;
        font-size: 16px;
        //font-family: Montserrat;
      }
      .ant-input-number-input::-ms-input-placeholder {
        font-weight: 500;
        color: #9A9EA0;
        font-size: 16px;
        //font-family: Montserrat;
      }
    }
  }

  .text_area {
    //min-height: 50px;
    //line-height: 45px;
    //background-color: #132024;
    line-height: 19px;
    border-radius: 8px;
    //overflow: hidden;
    border: 0;
    //padding: 18px 41px 10px 21px;
    //padding-top: 10px;
    //padding-bottom: 10px;
    font-weight: 500;
    color: #FFFFFF;
    font-size: 16px;
    &::after {
      color: rgb(255, 255, 255)
    }
    :global {
      .ant-input {
        min-height: 50px;
        background-color: #132024;
        border: unset;
        border-radius: 8px;
        font-weight: 500;
        color: #FFFFFF;
        font-size: 16px;
        //line-height: 19px;
      }
      .ant-input::-webkit-input-placeholder {
        font-weight: 500;
        color: #9A9EA0;
        font-size: 16px;
        //font-family: Montserrat;
      }
      .ant-input::-moz-placeholder {
        font-weight: 500;
        color: #9A9EA0;
        font-size: 16px;
        //font-family: Montserrat;
      }
      .ant-input::-ms-input-placeholder {
        font-weight: 500;
        color: #9A9EA0;
        font-size: 16px;
        //font-family: Montserrat;
      }
    }
    //background: rgba(0, 0, 0, 0) !important;
  }

  .btn_wrapper {
    margin-top: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .cancel_btn {
      width: 130px;
      height: 40px;
      border-radius: 8px;
      color: #F488B9;
      border: 1px solid #F488B9;
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
      cursor: pointer;
      user-select: none;
    }
    .send_btn {
      width: 130px;
      height: 40px;
      border-radius: 8px;
      color: #590B57;
      border: unset;
      background-color: #F488B9;
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

@media only screen and (min-width: 800px) {
  .screenShots_page {
      min-height: 100vh;
      .screenShots {
        width: 100%;
        padding: 0 60px;
      }
      .screenShots_close {
        width: 100%;
        padding: 0 60px;
      }

  }
}

@media only screen and (min-width: 1020px) {
   .screenShots_page {
       min-height: 100vh;
       .screenShots {
         width: 100%;
         padding: 0 60px;
       }
       .screenShots_close {
         width: 100%;
         padding: 0 60px;
       }

   }
}

@media only screen and (min-width: 1100px) {
  .screenShots_page {
    min-height: 100vh;
    .screenShots {
      width: 1100px;
      padding: 0 60px;
      .screenShots_wrapper{
        .screenShots_content{
          .screenShots_content_masonry{
            > div {
              width: 25%;
            }
          }
        }
      }
    }
    .screenShots_close {
      width: 100%;
      padding: 0 60px;
   }
  }
}

@media only screen and (min-width: 1200px) {
  .screenShots_page {
    min-height: 100vh;
    .screenShots {
      width: 1200px;
      padding: 0 60px;
      .screenShots_wrapper{
        .screenShots_content{
          .screenShots_content_masonry{
            > div {
              width: 25%;
            }
          }
        }
      }
    }
    .screenShots_close {
      width: 100%;
      padding: 0 60px;
    }
  }
}

@media only screen and (min-width: 1300px) {
  .screenShots_page {
    min-height: 100vh;
    .screenShots {
      width: 1300px;
      padding: 0 60px;
      .screenShots_wrapper{
        .screenShots_content{
          .screenShots_content_masonry{
            > div {
              width: 25%;
            }
          }
        }
      }
    }
    .screenShots_close {
      width: 100%;
      padding: 0 60px;
    }
  }
}

@media only screen and (min-width: 1400px) {
  .screenShots_page {
    min-height: 100vh;
    .screenShots {
      width: 1400px;
      padding: 0 60px;
      .screenShots_wrapper{
        .screenShots_content{
          .screenShots_content_masonry{
            > div {
              width: 25%;
            }
          }
        }
      }
    }
    .screenShots_close {
      width: 100%;
      padding: 0 60px;
    }
  }
}

@media only screen and (min-width: 1650px) {
  .screenShots_page {
    min-height: 100vh;
    .screenShots {
      width: 1500px;
      padding: 0 60px;
      .screenShots_wrapper{
        .screenShots_content{
          .screenShots_content_masonry{
            > div {
              width: 20%;
            }
          }
        }
      }
    }
    .screenShots_close {
      width: 100%;
      padding: 0 60px;
   }
  }
}

@media only screen and (min-width: 1900px) {
  .screenShots_page {
    min-height: 100vh;
    .screenShots {
      width: 1500px;
      margin: 0 auto;
      .screenShots_wrapper{
        .screenShots_content{
          .screenShots_content_masonry{
            > div {
              width: 16.67%;
            }
          }
        }
      }
    }
    .screenShots_close {
      width: 1670px;
      margin: 0 auto;
    }
  }
}

@media only screen and (max-width: 640px) {

  .reward_modal_page {
    padding: .35rem .32rem .32rem;
    .header_wrapper {
      margin-bottom: .22rem;
    }
    .user_info_wrapper {
      margin-bottom: .22rem;
    }
    .input_wra {
      height: .8rem;
      line-height: .8rem;
      font-size: .24rem;
      :global {
        .ant-input-number-input::-webkit-input-placeholder {
          font-size: .24rem;
        }
        .ant-input-number-input::-moz-placeholder {
          font-size: .24rem;
        }
        .ant-input-number-input::-ms-input-placeholder {
          font-size: .24rem;
        }
      }
    }
    .text_area {
      font-size: .24rem;
      :global {
        .ant-input {
          min-height: 1.3rem;
          font-size: .24rem;
        }
        .ant-input::-webkit-input-placeholder {
          font-size: .24rem;
        }
        .ant-input::-moz-placeholder {
          font-size: .24rem;
        }
        .ant-input::-ms-input-placeholder {
          font-size: .24rem;
        }
      }
    }
    .btn_wrapper {
      margin-top: .92rem;
      .cancel_btn {
        width: 2rem;
        height: .7rem;
        font-size: .24rem;
      }
      .send_btn {
        width: 2rem;
        height: .7rem;
        font-size: .24rem;
      }
    }
  }

    .screenShots_page {
        background: none;
        min-height: 1200px;
        background-size: 100%;
        background-color: #0B0C0E;
        padding-top: 0rem;
        .screenShots {
            width: 1100px;
            margin: 30px auto;
            //    height: 1000px;
            position: relative;
            background-color: rgba(1, 17, 24, 0.4);
            display: none;

            .screenShots_wrapper {
              width: 100%;
              min-height: 821px;
            //   padding: 30px 70px 47px 70px;

              // .screenShots_header {
              //   background-size: cover;
              //   border-radius: 10px;
              //   height: 130px;
              //   display: flex;
              //   padding: 0 40px 0 50px;
              //   margin: 0px 10px;
              //   .title {
              //     width: 15%;
              //     font-size: 21px;
              //     display: flex;
              //     color: #ffffff;
              //     align-self: center;

              //     .line {
              //       width: 5px;
              //       height: 27px;
              //       align-self: center;
              //       margin-right: 15px;
              //       background: $themeColor;
              //       border-radius: 25px;
              //     }
              //   }

              //   .tabBar {
              //     width: 21.25%;
              //     align-self: center;
              //     padding: 15px 10px;

              //     .img_div {
              //       width: 100%;
              //       overflow: hidden;
              //       border-radius: 7px;
              //       img {
              //         border-radius: 7px;
              //         width: 100%;
              //         height: 80px;
              //         object-fit: cover;
              //         -webkit-user-drag: none;
              //         transition: all 0.2s;
              //       }

              //       img:hover {
              //         border-radius: 7px;
              //         transform: scale(1.3);
              //       }
              //     }
              //   }

              //   .skeletonBar {
              //     width: 100%;
              //     height: 80px;
              //     border-radius: 7px;
              //   }
              // }

              // .screenShots_content {
              //   width: 100%;
              //   border-radius: 10px;
              //   margin-top: 22px;
              //   .screenShots_content_masonry {
              //     width: 100%;
              //     .skeleton {
              //       width: 300px;
              //       height: 300px;
              //     }
              //     .item {
              //       background: #111F2A;
              //       border-radius: 10px;
              //       width: 300px;
              //       display: flex;
              //       min-height: 340px;
              //       flex-direction: column;
              //       position: relative;
              //       color: #ffffff;
              //       margin: 10px 10px;
              //       .week_best {
              //         background: url("../../imgs/screent-label-weekbest.png") no-repeat;
              //         position: absolute;
              //         background-size: cover;
              //         width: 100px;
              //         height: 30px;
              //         top: 7px;
              //         left: -7px;
              //         color: #fff;
              //         text-align: center;
              //         line-height: 25px;
              //         font-weight: bold;
              //       }

              //       .hot_deal {
              //         background: url("../../imgs/screen-label-hotdeal.png") no-repeat;
              //         position: absolute;
              //         background-size: cover;
              //         width: 97px;
              //         height: 32px;
              //         top: 7px;
              //         left: -7px;
              //         color: #fff;
              //         text-align: center;
              //         font-weight: bold;
              //         line-height: 25px;
              //       }

              //       .item_img_div {
              //         width: 100%;
              //         min-height: 300px;
              //         border-top-left-radius: 10px;
              //         border-top-right-radius: 10px;
              //         overflow: hidden;
              //       }

              //       .item_img {
              //         width: 100%;
              //         min-height: 300px;
              //         border-top-left-radius: 10px;
              //         border-top-right-radius: 10px;
              //         object-fit: cover;
              //         -webkit-user-drag: none;
              //         transition: all 0.2s;
              //       }
              //     ;

              //       .item_img:hover {
              //         cursor: zoom-in;
              //         transform: scale(1.2);
              //       }

              //       .item_user {
              //         position: absolute;
              //         display: flex;
              //         justify-content: space-between;
              //         padding: 20px 7px;
              //         width: 100%;
              //         bottom: 53px;
              //         height: 40px;

              //         .share_div {
              //           margin-top: 45px;
              //           display: flex;
              //           justify-content: space-evenly;
              //           text-align: center;
              //           align-content: center;
              //           width: 20%;
              //           font-size: 9px;
              //           color: #ffffff;
              //           font-weight: normal
              //         }

              //         .username_div {
              //           text-align: center;
              //           width: 60%;
              //           .headPic_div {

              //           }

              //           .username {
              //             font-size: 13px;
              //             overflow: hidden;
              //             text-overflow: ellipsis;
              //             white-space: nowrap;
              //           }
              //         }

              //         .star_div {
              //           margin-top: 32px;
              //           display: flex;
              //           width: 20%;
              //           font-size: 7px;
              //           justify-content: space-evenly;
              //           text-align: center;
              //           line-height: 47px;
              //           color: #ffffff;
              //         }

              //         .shareIcon {
              //           background: url("../../imgs/screen-shot-see.png") no-repeat;
              //           width: 22px;
              //           height: 14px;
              //           background-size: cover;
              //           margin-left: 5px;
              //           margin-top: 1px;
              //         }

              //         .giveStar {
              //           background: url("../../imgs/fire-stop-3x.png") no-repeat;
              //           width: 24px;
              //           height: 24px;
              //           margin-top: 10px;
              //           margin-right: 5px;
              //           background-size: cover;
              //         }

              //         .star {
              //           background: url("../../imgs/fire-start.png") no-repeat;
              //           width: 23px;
              //           height: 23px;
              //           background-size: cover;
              //           margin-top: 10px;
              //           margin-right: 5px;
              //         }
              //       }
              //     ;

              //       .item_fire {
              //         position: absolute;
              //         bottom: 55px;
              //         left: 15px;
              //         color: #fff;
              //         font-size: 11px;
              //         height: 30px;
              //         line-height: 30px;
              //         display: flex;
              //         align-content: center;

              //         .starFire {
              //           background: url("../../imgs/Srceenfire.png") center no-repeat;
              //           width: 20px;
              //           height: 20px;
              //           background-size: cover;
              //           margin-right: 5px;
              //         }
              //       }
              //     ;

              //       .item_video_play {
              //         background: url("../../imgs/videoPlay.png") center;
              //         width: 29.5px;
              //         height: 29px;
              //         right:5px;
              //         top: 5px;
              //         background-size: cover;
              //         position: absolute;
              //       }

              //       .item_white {
              //         height: 40px;
              //       }

              //       //banner css
              //       .item_img_div_banner {
              //         border-radius: 10px;
              //         min-height: 300px;
              //         overflow: hidden;
              //         position: relative;
              //       }

              //       .item_img_banner {
              //         width: 100%;
              //         border-radius: 10px;
              //         height: 300px;
              //         object-fit: cover;
              //         -webkit-user-drag: none;
              //       }
              //     ;

              //       .item_img_banner_title_div {
              //         width: 100%;
              //         height: 70px;
              //         border-radius: 7px;
              //         position: absolute;
              //         bottom: 0;
              //       }

              //       .item_img_banner_title {
              //         width: 100%;
              //         height: 60px;
              //         border-radius: 10px;
              //         text-align: center;
              //         position: absolute;
              //         font-size: 15px;
              //         font-weight: bold;
              //         color: #ffffff;
              //         bottom: 0px;
              //       }

              //       .item_img_active_button {
              //         background: #F26E4C;
              //         margin: 7px 10px;
              //         padding: 10px;
              //         border-radius: 7px;
              //         text-align: center;
              //         font-weight: 600;
              //         color: #ffffff;

              //         .upload_icon {
              //           background: url("../../imgs/upload-icon.png") no-repeat;
              //           background-size: cover;
              //           width: 11px;
              //           height: 11px;
              //           display: inline-block;
              //           margin-left: 5px;
              //         }
              //       }
              //     }

              //     .item_banner {
              //       background: #111F2A;
              //       border-radius: 10px;
              //       width: 300px;
              //       display: flex;
              //       flex-direction: column;
              //       position: relative;
              //       margin: 7px 10px;
              //       //banner css
              //       .item_img_div_banner {
              //         border-radius: 10px;
              //         overflow: hidden;
              //         min-height: 300px;
              //         position: relative;
              //       }

              //       .item_img_banner {
              //         width: 100%;
              //         border-top-right-radius: 7px;
              //         border-top-left-radius: 7px;
              //         height: 300px;
              //         object-fit: cover;
              //         -webkit-user-drag: none;
              //       }

              //       .item_img_banner_title_div {
              //         width: 100%;
              //         height: 70px;
              //         position: absolute;
              //         bottom: 0px;
              //       }

              //       .item_img_banner_title {
              //         width: 100%;
              //         border-radius: 10px;
              //         text-align: center;
              //         position: absolute;
              //         font-size: 15px;
              //         font-weight: bold;
              //         color: #ffffff;
              //         bottom:7px;
              //       }
              //       .item_white{
              //         border-bottom-right-radius: 10px;
              //         border-bottom-left-radius: 10px;
              //         height: 70px;
              //       }
              //       .item_img_active_button {
              //         background: #F26E4C;
              //         margin: 7px 10px;
              //         padding: 10px;
              //         border-radius: 7px;
              //         text-align: center;
              //         font-weight: 600;
              //         color: #ffffff;

              //         .upload_icon {
              //           background: url("../../imgs/upload-icon.png") no-repeat;
              //           background-size: cover;
              //           width: 11px;
              //           height: 11px;
              //           display: inline-block;
              //           margin-left: 5px;
              //         }
              //       }
              //     }
              //   }
              // }

            }
          }

       .screenShots_mobile {
            display: block;
            width: 100%;
            margin: 0 auto 0;
            //    height: 1000px;
            position: relative;
            background-color: rgba(1, 17, 24, 0.4);

            .screenShots_wrapper {
              width: 100%;
              min-height: 821px;
              margin-top: 0;
              padding: .2rem;

              .title {
                width: 100%;
                color: white;
                font-size: 17px;
                font-weight: bold;
                display: flex;
                align-self: center;

                .line {
                  width: 4px;
                  height: 18px;
                  align-self: center;
                  margin-left: 2.5vw;
                  margin-right: 11px;
                  background: $themeColor;
                  border-radius: 25px;
                }
              }

              .screenShots_header {
                width: 7.1rem;
                display: flex;
                white-space:nowrap;
                overflow-x: scroll;
                margin-top: 15px;
                .tabBar {
                  // margin:0 5vw 0 5vw;
                  margin: 0 .5rem 0 0;
                  // margin-bottom: -10px !important;
                  .img_div {
                    width: 18vw;
                    overflow: hidden;
                    height: 18vw;
                    border-radius: 50%;
                    .img {
                      border-radius: 50%;
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                      -webkit-user-drag: none;
                      transition: all 0.2s;
                    }
                    .img_fail{
                      border-radius: 50%;
                      width: 100%;
                      height: 100%;
                      border: 2px solid white;
                      object-fit: cover;
                      -webkit-user-drag: none;
                      transition: all 0.2s;
                      -webkit-filter: grayscale(100%);
                      -moz-filter: grayscale(100%);
                      -o-filter: grayscale(100%);
                      filter: grayscale(100%);
                    }
                    .img:hover {
                      transform: scale(1);
                    }
                    .img_fail:hover {
                      transform: scale(1);
                    }
                  }
                  .game_name{
                    width: 100%;
                    text-align: center;
                    text-overflow: ellipsis;
                    color: #fff;
                    overflow: hidden;
                    display: inline-block;
                    margin-top: 7px;
                    white-space: nowrap
                  }
                }
                .skeletonBar {
                  border-radius: 50%;
                  width: 18vw;
                  height: 18vw;
                }
                .box_mob{
                  width: 5vw;
                }
              }
              .screenShots_header_z {
                margin-bottom: .4rem;
              }
              .topic{
                margin-top: .34rem;
                .title {
                  width:100%;
                  font-size: 17px;
                  display: flex;
                  color: #ffffff;
                  align-self: center;
                  cursor: pointer;
                  .line {
                    width: 5px;
                    height: 18px;
                    align-self: center;
                    margin-right: 11px;
                    background: $themeColor;
                    border-radius: 25px;
                  }
                }
                .topic_list{
                  display: block;
                  margin-top: 7px;
                  .topic_list_item{
                    background: #2a2f3180;
                    border-radius: 8px;
                    display: flex;
                    padding: 10px;
                    cursor: pointer;
                    color: #FFFFFF;
                    margin-bottom: .2rem;
                    .topic_details{
                      padding: 5px 10px;
                      display: flex;
                      flex-direction: column;
                      justify-content: space-between;
                      .topic_title{
                        font-size: 15px;
                        line-height: 21px;
                        font-weight: 600;
                        display: flex;
                        .hot_icon{
                          background: url("../../imgs/hot_icon.svg") no-repeat;
                          width: 35px;
                          height: 20px;
                          background-size: cover;
                          margin-left: 5px;
                        }
                      }
                      span{
                        margin: 0 10px 0 0;
                        font-size: 13px;
                        color: #c5cfd5b3;
                        font-weight: 500;
                      }
                    }
                    .topic_img{
                      width: 79px;
                      height: 60px;
                      object-fit: cover;
                      border-radius: 8px;
                    }
                  }
                  .topic_list_item:hover{
                    background: #30444E;
                  }
                }
              }
              .screenShots_content {
                width: 100%;
                border-radius: 7px;
                .screenShots_content_masonry {
                  width: 98vw;
                  margin: 0 auto;
                  .skeleton {
                    width: 47vw;
                    border-radius: 7px;
                    height: 200px;
                  }

                  .item {
                    background: #111F2A;
                    border-radius: 7px;
                    width: 46vw;
                    margin: 1.5vw;
                    min-height: 230px;
                    color: #ffffff;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    .week_best {
                      background: url("../../imgs/screent-label-weekbest.png") no-repeat;
                      position: absolute;
                      background-size: cover;
                      width: 81px;
                      height: 25px;
                      top: 7px;
                      left: -5px;
                      color: #fff;
                      text-align: center;
                      line-height: 21px;
                      font-size: 12px;
                    }
                    .hot_deal {
                      background: url("../../imgs/screen-label-hotdeal.png") no-repeat;
                      position: absolute;
                      background-size: cover;
                      width: 80px;
                      height: 22px;
                      top: 7px;
                      left: -5px;
                      color: #fff;
                      text-align: center;
                      line-height: 19px;
                      font-size: 12px;
                    }
                    .item_img_div {
                      border-top-left-radius: 7px;
                      border-top-right-radius: 7px;
                      min-height: 200px;
                      overflow: hidden;
                    }
                    .item_img {
                      width: 100%;
                      border-top-left-radius: 7px;
                      border-top-right-radius: 7px;
                      object-fit: cover;
                      min-height: 200px;
                      -webkit-user-drag: none;
                      transition: all 0.6s;
                    }
                    .item_img:hover {
                      transform: scale(1.1);
                    }

                    .userAvatar {
                      position: absolute;
                      border:  1px solid rgba(255, 255, 255, 0.7);
                      border-radius: 50%;
                      z-index: 1;
                      left: 50%;
                      bottom: 4px;
                      transform: translateX(-50%);
                      cursor: pointer;
                      width: .6rem;
                      height: .6rem;
                    }
                    .item_userInfo {
                      width: 100%;
                      display: flex;
                      justify-content: space-between;
                      background-color: #111F2A;
                      min-height: 40px;
                      border-radius: 0 0 10px 10px;
                      // position: absolute;
                      // width: 100%;
                      // display: flex;
                      // justify-content: space-between;
                      // bottom: 5px;
                      .share_div {
                        // margin-top: 35px;
                        margin-left: 5px;
                        display: flex;
                        justify-content: space-evenly;
                        text-align: center;
                        align-items: center;
                        align-content: center;
                        // width: 20%;
                        font-size: 11px;
                        color: #ffffff;
                        font-weight: normal
                      }
                      .username {
                        display: none;
                      }
                      .star_div {
                        display: flex;
                        // width: 20%;
                        font-size: 11px;
                        justify-content: space-evenly;
                        align-items: center;
                        text-align: center;
                        color: #ffffff;
                      }

                      .shareIcon {
                        background: url("../../imgs/screen-shot-see.png") no-repeat;
                        width: 22px;
                        height: 14px;
                        background-size: cover;
                        // margin-left: 5px;
                        margin-top: 1px;
                      }

                      .giveStar {
                        background: url("../../imgs/fire-stop-3x.png") no-repeat;
                        width: 24px;
                        height: 24px;
                        // margin-top: 10px;
                        // margin-right: 5px;
                        margin: 0 5px;
                        cursor: pointer;
                        background-size: cover;
                      }

                      .star {
                        background: url("../../imgs/fire-start.png") no-repeat;
                        width: 23px;
                        height: 23px;
                        background-size: cover;
                        // margin-top: 10px;
                        // margin-right: 5px;
                        margin: 0 5px;
                        cursor: pointer;
                      }
                    }
                    .item_user {
                      position: absolute;
                      display: flex;
                      justify-content: space-between;
                      padding: 20px 7px;
                      width: 100%;
                      bottom: 35px;
                      height: 40px;
                      .share_div {
                        margin-top: 39px;
                        display: flex;
                        justify-content: flex-start;
                        text-align: center;
                        align-content: center;
                        width: 30%;
                        font-size: 9px;
                        color: #ffffff;
                        font-weight: normal;
                        text-align: center;
                        line-height: 13px;
                      }

                      .username_div {
                        text-align: center;
                        width: 40%;
                        .headPic_div {

                        }
                        .username {
                          overflow: hidden;
                          text-overflow: ellipsis;
                          white-space: nowrap;
                          font-size: 11px;
                        }
                      }

                      .star_div {
                        margin-top: 32px;
                        align-ontent: center;
                        font-weight: normal;
                        display: flex;
                        justify-content: flex-end;
                        width: 30%;
                        font-size: 9px;
                        text-align: center;
                        line-height: 27px;
                        color: #ffffff;
                      }

                      .shareIcon {
                        background: url("../../imgs/screen-shot-see.png") no-repeat;
                        width: 16px;
                        height: 11px;
                        margin-top: 1px;
                        background-size: cover;
                        margin-right: 3px;
                      }
                      .giveStar {
                        background: url("../../imgs/fire-stop-3x.png") no-repeat;
                        width: 16px;
                        height: 16px;
                        background-size: cover;
                        margin-top: 4px;
                        margin-left: 5px;
                      }
                      .star {
                        background: url("../../imgs/fire-start.png") no-repeat;
                        width: 16px;
                        height: 16px;
                        background-size: cover;
                        margin-top: 4px;
                        margin-left: 5px;
                      }
                    }
                  ;

                    .item_fire {
                      position: absolute;
                      bottom: 55px;
                      left: 7px;
                      color: #fff;
                      font-size: 11px;
                      height: 30px;
                      line-height: 30px;
                      display: flex;
                      align-content: center;

                      .starFire {
                        background: url("../../imgs/Srceenfire.png") center no-repeat;
                        width: 15px;
                        height: 15px;
                        background-size: cover;
                        margin-right: 3px;
                        margin-top: 5px;
                      }
                    }
                  ;

                    .item_video_play {
                      background: url("../../imgs/videoPlay.png") center;
                      width: 29.5px;
                      height: 29px;
                      right:5px;
                      top: 5px;
                      background-size: cover;
                      position: absolute;
                    }

                    .item_white {
                      height: 30px;
                    }

                  }

                  .item_banner {
                    background: #111F2A;
                    border-radius: 7px;
                    width: 46vw;
                    margin: 1.5vw;
                    position: relative;
                    //banner css
                    .item_img_div_banner {
                      border-radius: 7px;
                      overflow: hidden;
                      height: 46vw;
                      position: relative;
                    }

                    .item_img_banner {
                      width: 100%;
                      border-radius: 7px;
                      height: 46vw;
                      object-fit: cover;
                      -webkit-user-drag: none;
                    }

                    .item_img_banner_title_div {
                      width: 100%;
                      height: 45px;
                      border-radius: 7px;
                      position: absolute;
                      bottom: 0;
                    }

                    .item_img_banner_title {
                      width: 100%;
                      height: 35px;
                      border-radius: 7px;
                      text-align: center;
                      position: absolute;
                      font-size: 9px;
                      font-weight: bold;
                      color: #ffffff;
                      bottom: 5px;
                    }
                    .item_white{
                      border-bottom-right-radius: 10px;
                      border-bottom-left-radius: 10px;
                      height: 70px;
                    }

                    .item_img_active_button {
                      background: #F26E4C;
                      margin: 7px 10px;
                      padding: 5px;
                      font-size: 11px;
                      border-radius: 5px;
                      text-align: center;
                      color: #ffffff;
                    }
                  }
                }
              }
              .select_content {
                margin-top: .25rem;
                margin-bottom: .22rem;
                // padding:  .2rem .2rem 0 .2rem;
                .scroll_wrap{
                  position: relative;
                  display: flex;
                  align-items: center;
                  .space_between{
                    width: calc(100% - .96rem);
                    display: flex;
                    overflow-x: auto;
                    white-space: nowrap;
                    justify-content: flex-start;
                    padding-right: .6rem;
                    h1 {
                      display: inline-block;
                      color: #fff;
                      font-weight: 600;
                      padding:0 .5rem 0 .1rem;
                      font-size: 15px !important;
                      margin-bottom: 0;
                      line-height: 30px;
                      margin-right: 0;
                    }
                    .active_tab{
                      font-weight: 800;
                      color: #F26E4C;
                    }
                  }
                  .mobile_select{
                    position: absolute;
                    right: 0;
                    width: .96rem;
                    height: 37px;
                    background: linear-gradient(90deg, #070E13 0%, #0F1517 60%);
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    z-index: 999;
                    padding-right: .2rem;
                    .select_arrow{
                      background: url("../../imgs/select_arrow.png") no-repeat !important;
                      background-position: 100% 100%;
                      position:relative;
                      -o-background-size: contain;
                      -moz-background-size: contain;
                      -webkit-background-size: contain;
                      height: 10px;
                      width: 17px;
                      background-size: contain !important;
                      transform: rotateX(180deg);
                    }
                    .select_arrow_rot{
                      transform: rotateX(360deg);
                    }
                  }
                }
                .select_wrap {
                  display: none;
                }

              }


            }

            .new_upLoad_post{
              position: fixed;
              bottom: 10px;
              background: #1d2e38;
              border: 0.3px solid rgba(11, 12, 14, 0.30);
              // right: 0px;
              // left: 0px;
              // margin: 0 auto;
              // width:50vw;
              left: 50%;
              transform: translateX(-50%);
              display: flex;
              border-radius: 20vw;

              height: 15vw;
              padding: 2.5vw 3.5vw;
              .post_icon{
                font-size: 0px;
                background: url("../../imgs/new_post_up.png") no-repeat;
                background-size: contain;
                width: 30vw;
                height: 15vw;
              }
            }
            .upToTop_icon{
              position: fixed;
              right: 2rem;
              bottom: 10px;
              background: url("../../imgs/upToTop.png") no-repeat;
              width:10vw;
              height: 10vw;
              margin-left: 3vw;
              background-size: contain;
            }
          }
       .screenShots_mobile_tagPage{
        margin: .70rem auto 0;
        }

       .dropHeight{
         margin: -.5rem auto 0;
       }

    }





}


