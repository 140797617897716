.item {
  background: #0F1517;
  border-radius: 12px;
  // width: 290px;
  // width: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  position: relative;
  color: #ffffff;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  font-family: Roboto;
  border: 1px solid transparent;
  background-clip:padding-box;
  cursor: pointer;
  &::after {
    transition: all 0.6s ease 0s;
    position: absolute;
    inset: -1px;
    //background: linear-gradient(#fc7c1c,#f9c333);
    background: linear-gradient(#F488B9,#BA9BBE);
    content: "";
    z-index: 1;
    border-radius: 12px;
    opacity: 0;
  }
  .week_best {
    background: url("../../imgs/screent-label-weekbest.png") no-repeat;
    position: absolute;
    background-size: cover;
    width: 100px;
    height: 30px;
    top: 7px;
    left: -7px;
    color: #fff;
    text-align: center;
    line-height: 25px;
    font-weight: bold;
    z-index: 9;
  }

  .hot_deal {
    background: url("../../imgs/screen-label-hotdeal.png") no-repeat;
    position: absolute;
    background-size: cover;
    width: 97px;
    height: 32px;
    top: 7px;
    left: -7px;
    color: #fff;
    text-align: center;
    font-weight: bold;
    line-height: 25px;
  }

  .item_img_div {
    width: 100%;
    // min-height: 300px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    overflow: hidden;
    min-height: 80px;
    //min-height: 120px;
    z-index: 2;
    position: relative;

    .multiple_wrapper {
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 3;
      display: flex;
      align-items: center;
      .multiple_icon {
        width: 16px;
        height: 16px;
        background: url("../../imgs/discover/template.png") center no-repeat;
        background-size: contain;
      }
      .multiple_num {
        margin-left: 5px;
        font-size: 15px;
        font-weight: 500;
        color: #FFFFFF;
      }
    }

    .vague_div {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 3;
      backdrop-filter: blur(25px);
      -webkit-backdrop-filter: blur(25px);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      font-weight: 600;
    }

    .left_up_wrapper {
      position: absolute;
      left: 10px;
      top: 10px;
      display: flex;
      align-items: center;
      .eye_icon {
        width: 20px;
        height: 20px;
        z-index: 3;
        margin-right: 3px;
        background: url("../../imgs/generate/eye_icon.png") center no-repeat;
        background-size: contain;
        cursor: pointer;
      }

      .eye_close_icon {
        width: 20px;
        height: 20px;
        z-index: 3;
        margin-right: 3px;
        background: url("../../imgs/generate/eye_close_icon.png") center no-repeat;
        background-size: contain;
        cursor: pointer;
      }

      .info_icon {
        width: 17px;
        height: 17px;
        z-index: 3;
        //margin-left: 8px;
        background: url("../../imgs/generate/info_icon.png") center no-repeat;
        background-size: contain;
        cursor: pointer;
      }
    }

    .item_img {
      width: 100%;
      aspect-ratio: 1;
      object-fit: cover;
      object-position: top left;
      -webkit-user-drag: none;
      transition: all 0.4s;
    }
    .item_img:hover {
      cursor: pointer;
      transform: scale(1.2);
    }

    .emoji_wrapper {
      position: absolute;
      bottom: 10px;
      left: 10px;
      //width: 129px;
      min-width: 30px;
      height: 28px;
      z-index: 3;
      background-color: rgba(0, 0, 0, 0.7);
      transition: all 0.3s ease-out;
      border-radius: 5px;
      display: -webkit-box;
      padding: 0 3px;
      overflow: hidden;
      //&:hover {
      //  width: 200px;
      //}
      .emoji_item {
        width: auto;
        height: 28px;
        display: flex;
        align-items: center;
        .emoji_box {
          height: 18px;
        }
        .number {
          font-size: 15px;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 18px;
          margin-left: 3px;
          margin-right: 3px;
        }
      }
      .add_icon {
        background: url("../../imgs/gamieZone/add_icon.png") center no-repeat;
        background-size: contain;
        width: 18px;
        height: 28px;
        cursor: pointer;
        margin: 0 3px;
      }
    }
    .emoji_wrapper_width {
      width: auto;
    }
  }
  .post_info {
    z-index: 2;
    background-color: #0F1517;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    .nft_pro_info {
      padding: 13px 16px;
      .description {
        font-size: 14px;
        line-height: 20px;
        text-align: left;
        color: #D8D8D8;
        -webkit-transition: color .2s ease;
        transition: color .2s ease;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-weight: 600;


      }
      .price_info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
        .price_wrapper {
          display: flex;
          align-items: center;
          .igm_logo {
            background: url('../../imgs/IGM@2x.webp') center no-repeat;
            background-size: contain;
            width: 20px;
            height: 20px;
          }
          .price_num {
            color: #F7B500;
            font-size: 17px;
            font-weight: 700;
            margin-left: 5px;
            display: flex;
            .igm_name {
              display: none;
            }
            .price {
              width: 80px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              margin-left: 4px;
            }
          }
        }
        .chain_wrapper {
          .chain_w {
            display: flex;
            align-items: center;
            .erc_icon {
              background: url('../../imgs/igm_withdrawal/eth_icon.png') center no-repeat;
              background-size: contain;
              display: inline-block;
              width: 20px;
              height: 20px;
            }
            .bep_icon {
              background: url('../../imgs/igm_withdrawal/bep_icon.png') center no-repeat;
              background-size: contain;
              display: inline-block;
              width: 20px;
              height: 20px;

            }
            .name {
              font-size: 17px;
              font-weight: 700;
              margin-left: 4px;
            }
          }
        }
      }
    }
    .item_top_wrapper {
      display: flex;
      justify-content: space-between;
      padding: 10px 10px;
      .item_title {
        width: 20vw;
        font-size: 14px;
        //font-family: Montserrat-SemiBold, Montserrat;
        font-weight: 600;
        color: #D8D8D8;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .download_icon {
        background: url("../../imgs/generate/download_icon.png") center no-repeat;
        background-size: contain;
        width: 30px;
        height: 30px;
      }
    }
    .item_userInfo {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #0F1517;
      //min-height: 50px;
      border-radius: 0 0 12px 12px;
      position: relative;
      padding: 4px 15px;
      border-top: 1px solid #97979718;
      .left_wrapper {
        width: 70%;
        display: flex;
        align-items: center;
        .userAvatar {
          border:  1px solid rgba(255, 255, 255, 0.35);
          border-radius: 50%;
          z-index: 1;
          cursor: pointer;
          img {
            width: 45px;
            height: 45px;
            overflow: hidden;
            border-radius: 50%;
            object-fit: cover;
          }
        }
        .item_title {
          margin-left: 8px;
          width: 80px;
          font-size: 15px;
          //font-family: Montserrat-SemiBold, Montserrat;
          font-weight: 600;
          color: #D8D8D8;
          display: flex;
          flex-direction: column;
          .title {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .user_name {
            font-size: 12px;
            font-weight: 500;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
      .right_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        //padding: 10px 0;
        .star_div {
          // margin-top: 20px;
          display: flex;
          //width: 20%;
          font-size: 11px;
          justify-content: space-evenly;
          align-items: center;
          text-align: center;
          // line-height: 47px;
          color: #ffffff;
          .giveStar {
            background: url("../../imgs/likeIcon.svg") no-repeat;
            width: 24px;
            height: 24px;
            // margin-top: 10px;
            cursor: pointer;
            background-size: cover;
            //margin-left: 3px;
          }

          .star {
            background: url("../../imgs/postLikeIcon.svg") no-repeat;
            width: 24px;
            height: 24px;
            background-size: cover;
            // margin-top: 10px;
            cursor: pointer;
            //margin-left: 3px;
          }
        }
        .send_tip_icon {
          width: 24px;
          height: 24px;
          background: url("../../imgs/discover/tips_Icon.png") center no-repeat;
          background-size: contain;
          cursor: pointer;
          z-index: 3;
        }
      }
      .share_div {
        // margin-top: 35px;
        display: flex;
        text-align: center;
        align-items: center;
        align-content: center;
        font-size: 11px;
        color: #ffffff;
        font-weight: normal
      }
      .username {
        font-size: 13px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
        padding-top: 10px;
      }


      .shareIcon {
        background: url("../../imgs/screen-shot-see.png") no-repeat;
        width: 22px;
        height: 14px;
        background-size: cover;
        margin-right: 3px;
        margin-top: 1px;
      }
    }
  }


  .item_user {
    position: absolute;
    display: flex;
    justify-content: space-between;
    padding: 20px 7px;
    width: 100%;
    bottom: 40px;
    height: 40px;

    .share_div {
      margin-top: 35px;
      margin-left: 5px;
      display: flex;
      justify-content: space-evenly;
      text-align: center;
      align-content: center;
      width: 20%;
      font-size: 11px;
      color: #ffffff;
      font-weight: normal
    }

    .username_div {
      text-align: center;
      width: 60%;
      .headPic_div {
        cursor: pointer;
      }

      .username {
        font-size: 13px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
      }
    }

    .star_div {
      margin-top: 20px;
      display: flex;
      width: 20%;
      font-size: 11px;
      justify-content: space-evenly;
      text-align: center;
      line-height: 47px;
      color: #ffffff;
    }

    .shareIcon {
      background: url("../../imgs/screen-shot-see.png") no-repeat;
      width: 22px;
      height: 14px;
      background-size: cover;
      // margin-left: 5px;
      margin-top: 1px;
    }

    .giveStar {
      background: url("../../imgs/fire-stop-3x.png") no-repeat;
      width: 24px;
      height: 24px;
      margin-top: 10px;
      cursor: pointer;
      background-size: cover;
    }

    .star {
      background: url("../../imgs/fire-start.png") no-repeat;
      width: 23px;
      height: 23px;
      background-size: cover;
      margin-top: 10px;
      margin-right: 5px;
      cursor: pointer;
    }
  }
;

  .item_fire {
    position: absolute;
    bottom: 55px;
    left: 15px;
    color: #fff;
    font-size: 11px;
    height: 30px;
    line-height: 30px;
    display: flex;
    align-content: center;

    .starFire {
      background: url("../../imgs/Srceenfire.png") center no-repeat;
      width: 20px;
      height: 20px;
      background-size: cover;
      margin-right: 5px;
    }
  }
;

  .item_video_play {
    background: url("../../imgs/videoPlay.png") center;
    width: 29.5px;
    height: 29px;
    right:5px;
    top: 5px;
    background-size: cover;
    position: absolute;
  }

  .item_white {
    height: 40px;
  }

  //banner css
  .item_img_div_banner {
    border-radius: 10px;
    min-height: 300px;
    overflow: hidden;
    position: relative;
  }

  .item_img_banner {
    width: 100%;
    border-radius: 10px;
    height: 300px;
    object-fit: cover;
    -webkit-user-drag: none;
  }
;

  .item_img_banner_title_div {
    width: 100%;
    height: 70px;
    border-radius: 7px;
    position: absolute;
    bottom: 0;
  }

  .item_img_banner_title {
    width: 100%;
    height: 60px;
    border-radius: 10px;
    text-align: center;
    position: absolute;
    font-size: 15px;
    font-weight: bold;
    color: #ffffff;
    bottom: 0px;
  }

  .item_img_active_button {
    background: #F26E4C;
    margin: 7px 10px;
    padding: 10px;
    border-radius: 7px;
    text-align: center;
    font-weight: 600;
    color: #ffffff;

    .upload_icon {
      background: url("../../imgs/upload-icon.png") no-repeat;
      background-size: cover;
      width: 11px;
      height: 11px;
      display: inline-block;
      margin-left: 5px;
    }
  }
}

.item:hover {
  &::after {
    opacity: 1;
  }
}
.skeleton {
    width: 300px;
    height: 300px;
    display: none;
}

@media only screen and (min-width: 1300px) {
  .item {
    .post_info {
      .nft_pro_info {
        .price_info {
          .price_wrapper {
            .price_num {
            }
            .price {
              width: 100px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

          }

        }

      }

    }
  }
}

@media only screen and (min-width: 1650px) {
  .item {
    .post_info {
      .nft_pro_info {
        .price_info {
          .price_wrapper {
            .price_num {
               width: 110px;
               .igm_name {
                display: block;
               }
               .price {
                width: 110px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 1900px) {
  .item {
    .post_info {
      .nft_pro_info {
        .price_info {
          .price_wrapper {
            .price_num {
            }
            .price {
              width: 120px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
}


@media screen and (max-width: 640px) {
    .item {
    background: #111F2A;
    border-radius: 7px;
    // margin: 1.5vw;
    // margin:  1.5vw .75vw 1.5vw .75vw;
    // margin: .2rem .1rem .2rem .1rem;
    min-height: 230px;
    color: #ffffff;
    position: relative;
    display: flex;
    flex-direction: column;
    .week_best {
      background: url("../../imgs/screent-label-weekbest.png") no-repeat;
      position: absolute;
      background-size: cover;
      width: 81px;
      height: 25px;
      top: 7px;
      left: -5px;
      color: #fff;
      text-align: center;
      line-height: 21px;
      font-size: 12px;
    }
    .hot_deal {
      background: url("../../imgs/screen-label-hotdeal.png") no-repeat;
      position: absolute;
      background-size: cover;
      width: 80px;
      height: 22px;
      top: 7px;
      left: -5px;
      color: #fff;
      text-align: center;
      line-height: 19px;
      font-size: 12px;
    }
    .item_img_div {
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      min-height: 200px;
      overflow: hidden;

      .item_img {
        width: 100%;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        object-fit: cover;
        min-height: 200px;
        -webkit-user-drag: none;
        transition: all 0.6s;
      }
      .item_img:hover {
        transform: scale(1.1);
      }

      .emoji_wrapper {
        position: absolute;
        bottom: 10px;
        left: 0;
        //width: 1.8rem;
        height: 34px;
        z-index: 3;
        background-color: rgba(0, 0, 0, 0.7);
        transition: all 0.5s ease-out;
        border-radius: 0;
        display: -webkit-box;
        padding: 0 1px;
        overflow: hidden;
        overflow-x: scroll;
        //&:hover {
        //  width: 200px;
        //}
        .emoji_item {
          width: 25px;
          height: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;

          .emoji_box {
            height: 18px;
          }
          .number {
            font-size: 15px;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 16px;
            //margin-left: 1px;
          }
        }
        .add_icon {
          background: url("../../imgs/gamieZone/add_icon.png") center no-repeat;
          background-size: contain;
          width: 16px;
          height: 28px;
          cursor: pointer;
          margin: 0 3px;
        }
      }
      .emoji_wrapper_width {
        width: 100%;
      }
    }
    .post_info {
      .nft_pro_info {
        padding: 10px;
        .description {
          color: #D8D8D8;
          font-size: .22rem;
          font-weight: 550;
          line-height: .25rem;
        }
        .price_info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 8px;
          .price_wrapper {
            display: flex;
            align-items: center;
            .igm_logo {
              background: url('../../imgs/IGM@2x.webp') center no-repeat;
              background-size: contain;
              width: .28rem;
              height: .28rem;
            }
            .price_num {
              color: #F7B500;
              font-size: .24rem;
              font-weight: 700;
              margin-left: 1px;
              display: flex;
              .igm_name {
                display: none;
              }
              .price {
                width: 80px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-left: 4px;
              }
            }
          }
          .chain_wrapper {
            .chain_w {
              display: flex;
              align-items: center;
              .erc_icon {
                background: url('../../imgs/igm_withdrawal/eth_icon.png') center no-repeat;
                background-size: contain;
                display: inline-block;
                width: .28rem;
                height: .28rem;
              }
              .bep_icon {
                background: url('../../imgs/igm_withdrawal/bep_icon.png') center no-repeat;
                background-size: contain;
                display: inline-block;
                width: .28rem;
                height: .28rem;

              }
              .name {
                font-size: .20rem;
                font-weight: 550;
                margin-left: 4px;
              }
            }
          }
        }

      }
      .item_userInfo {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        //background-color: #111F2A;
        min-height: .81rem;
        border-radius: 0 0 10px 10px;
        padding: .1rem .1rem;
        .left_wrapper {
          .userAvatar {
            border:  1px solid rgba(255, 255, 255, 0.35);
            border-radius: 50%;
            z-index: 1;
            cursor: pointer;
            width: .7rem;
            height: .7rem;
            img {
              width: .7rem;
              height: .7rem;
              overflow: hidden;
              border-radius: 50%;
              object-fit: cover;
            }
          }
          .item_title {
            color: #D8D8D8;
            font-size: .22rem;
            font-weight: 550;
            width: 1.5rem;
            line-height: .25rem;
            .user_name {
              font-size: .2rem;
              font-weight: 400;
            }
          }
        }
        .right_wrapper {
          .star_div {
            display: flex;
            width: unset;
            font-size: .24rem;
            justify-content: space-evenly;
            align-items: center;
            text-align: center;
            color: #ffffff;
            .giveStar {
              background: url("../../imgs/likeIcon.svg") no-repeat;
              width: 18px;
              height: 18px;
              // margin-top: 10px;
              // margin-right: 5px;
              cursor: pointer;
              background-size: cover;
              margin-left: .1rem;
            }

            .star {
              background: url("../../imgs/postLikeIcon.svg") no-repeat;
              width: 20px;
              height: 20px;
              background-size: cover;
              // margin-top: 10px;
              // margin-right: 5px;
              margin: 0 5px;
              cursor: pointer;
            }
          }
          .send_tip_icon {
            width: .45rem;
            height: .45rem;
          }
          .info_icon {
            width: 18px;
            height: 18px;
            z-index: 3;
            background: url("../../imgs/generate/info_icon.png") center no-repeat;
            background-size: contain;
            cursor: pointer;
            margin-top: .1rem;
          }
        }
        // position: absolute;
        // width: 100%;
        // display: flex;
        // justify-content: space-between;
        // bottom: 5px;
        .share_div {
          // margin-top: 35px;
          margin-left: 0px;
          display: flex;
          justify-content: space-evenly;
          text-align: center;
          align-items: center;
          align-content: center;
           width: unset;
          font-size: 11px;
          color: #ffffff;
          font-weight: normal
        }
        .username {
          display: none;
        }
        //.star_div {
        //  display: flex;
        //  width: unset;
        //  font-size: .24rem;
        //  justify-content: space-evenly;
        //  align-items: center;
        //  text-align: center;
        //  color: #ffffff;
        //}

        .shareIcon {
          background: url("../../imgs/screen-shot-see.png") no-repeat;
          width: 22px;
          height: 14px;
          background-size: cover;
          // margin-left: 5px;
          margin-right: .1rem;
          margin-top: -1.5px;
        }


      }
    }



    .item_user {
      position: absolute;
      display: flex;
      justify-content: space-between;
      padding: 20px 7px;
      width: 100%;
      bottom: 35px;
      height: 40px;
      .share_div {
        margin-top: 39px;
        display: flex;
        justify-content: flex-start;
        text-align: center;
        align-content: center;
        width: 30%;
        font-size: 9px;
        color: #ffffff;
        font-weight: normal;
        text-align: center;
        line-height: 13px;
      }

      .username_div {
        text-align: center;
        width: 40%;
        .headPic_div {

        }
        .username {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 11px;
        }
      }

      .star_div {
        margin-top: 32px;
        font-weight: normal;
        display: flex;
        justify-content: flex-end;
        width: 30%;
        font-size: 9px;
        text-align: center;
        line-height: 27px;
        color: #ffffff;
      }

      .shareIcon {
        background: url("../../imgs/screen-shot-see.png") no-repeat;
        width: 16px;
        height: 11px;
        margin-top: 1px;
        background-size: cover;
        margin-right: 3px;
      }
      .giveStar {
        background: url("../../imgs/fire-stop-3x.png") no-repeat;
        width: 16px;
        height: 16px;
        background-size: cover;
        margin-top: 4px;
        margin-left: 5px;
      }
      .star {
        background: url("../../imgs/fire-start.png") no-repeat;
        width: 16px;
        height: 16px;
        background-size: cover;
        margin-top: 4px;
        margin-left: 5px;
      }
    }
  ;

    .item_fire {
      position: absolute;
      bottom: 55px;
      left: 7px;
      color: #fff;
      font-size: 11px;
      height: 30px;
      line-height: 30px;
      display: flex;
      align-content: center;

      .starFire {
        background: url("../../imgs/Srceenfire.png") center no-repeat;
        width: 15px;
        height: 15px;
        background-size: cover;
        margin-right: 3px;
        margin-top: 5px;
      }
    }
  ;

    .item_video_play {
      background: url("../../imgs/videoPlay.png") center;
      width: 29.5px;
      height: 29px;
      right:5px;
      top: 5px;
      background-size: cover;
      position: absolute;
    }

    .item_white {
      height: 30px;
    }

  }
  .item:hover {

    .item_img {
      transform: scale(1);
    }
    &::after {
      opacity: 0;
    }
  }
    .skeleton {
        width: 47vw;
        border-radius: 7px;
        height: 200px;
    }
}
