.emoji1{
  background: url("../../imgs/emoji1.png") no-repeat;
  width: 85px;
  height: 45px;
  background-size: cover;
}
.emoji2{
  background: url("../../imgs/emoji2.png") no-repeat;
  width: 85px;
  height: 45px;
  background-size: cover;
}
.emoji3{
  background: url("../../imgs/emoji3.png") no-repeat;
  width: 34px;
  height: 34px;
  background-size: cover;
}
.emoji4{
  background: url("../../imgs/emoji4.png") no-repeat;
  width: 34px;
  height: 34px;
  background-size: cover;
}
.emoji5{
  background: url("../../imgs/emoji5.png") no-repeat;
  width: 34px;
  height: 34px;
  background-size: cover;
}
.emoji6{
  background: url("../../imgs/emoji6.png") no-repeat;
  width: 34px;
  height: 34px;
  background-size: contain;
}
.emoji7{
  background: url("../../imgs/emoji7.png") no-repeat;
  width: 34px;
  height: 34px;
  background-size: cover;
}
.emoji8{
  background: url("../../imgs/emoji8.png") no-repeat;
  width: 44px;
  height: 34px;
  background-size: cover;
}
.emoji9{
  background: url("../../imgs/emoji9.png") no-repeat;
  width: 34px;
  height: 34px;
  background-size: cover;
}
.emoji10{
  background: url("../../imgs/emoji10.png") no-repeat;
  width: 34px;
  height: 34px;
  background-size: cover;
}

.userProfile {
  .avatarInfo {
    display: flex;
    align-items: center;
    .nameInfo {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 15px;
      font-size: 17px;
      font-weight: bold;
      .follow {
        color: #f90;
        border: 2px solid #f90;
        border-radius: 5px;
      }
      .unFollow {
        color: #09f;
        border: 2px solid #09f;
        border-radius: 5px;
      }
    }
  }
  .otherInfo {
    display: flex;
    margin-top: 20px;
    padding-top: 10px;
    border-top: 1px solid #eee;
    .item {
      font-weight: 600;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin-right: 20px;
      .count {
        font-size: 20px;
      }
      .text {
        color: #bbb;
      }
    }
  }
}

.comments_parents {
  margin: 20px 0;
  //font-family: Montserrat;
  &:hover {
    .c_header_wrapper{
      .c_header_user {
        .c_delete_icon {
          display: block;
        }
      }
    }
  }
  .c_header_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .c_header_user {
      display: flex;
      align-items: center;
      .c_header_img {
        .c_header_img_Avatar {
          width: 36px;
          height: 36px;
          border: 2px solid #AD4909;
          cursor: pointer;
        }
      }
      .c_user_name {
        max-width: 100px;
        margin-left: 12px;
        font-weight: bold;
        font-size: 16px;
        color: #FFFFFF;
        //line-height: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
      }
      .c_time_ago {
        margin-left: 12px;
        font-size: 12px;
        font-weight: 500;
        color: #9CA0A2;
        line-height: 8px;
      }
      .c_delete_icon {
        display: none;
        width: 18px;
        height: 18px;
        background: url("../../imgs/delete-icon.png") center no-repeat;
        background-size: contain;
        cursor: pointer;
        margin-left: 5px;
      }
    }
    .c_action_wra {
      display: flex;
      align-items: flex-start;
      height: 40px;
      .c_thumbs_wra {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 10px;
        .c_thumbs_icon {
          width: 20px;
          height: 20px;
          background: url("../../imgs/gamieZone/thumbs-up.png") center no-repeat;
          background-size: contain;
          cursor: pointer;
        }
        .c_thumbs_icon_active {
          width: 20px;
          height: 20px;
          background: url("../../imgs/gamieZone/thumbs-up_active.png") center no-repeat;
          background-size: contain;
          cursor: pointer;
        }
        .c_thumbs_num {
          margin-top: 10px;
          font-size: 13px;
          font-weight: 600;
          color: #D0D1D1;
          line-height: 13px;
        }
      }
      .c_rec_wra {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 14px;
        margin-left: 20px;
        .c_rec_icon {
          width: 20px;
          height: 16px;
          background: url("../../imgs/gamieZone/message_icon.png") center no-repeat;
          background-size: contain;
          cursor: pointer;
        }
        .c_rec_icon_active {
          width: 20px;
          height: 16px;
          background: url("../../imgs/gamieZone/message_icon_active.png") center no-repeat;
          background-size: contain;
          cursor: pointer;
        }
        .c_rec_num {
          margin-top: 10px;
          font-size: 13px;
          font-weight: 600;
          color: #D0D1D1;
          line-height: 13px;
        }
      }
    }
  }
  .c_content_wrapper {
    padding: 0 10px 0 48px;
    font-weight: bold;
    color: #D0D1D1;
    font-size: 14px;
  }
  .c_child {
    margin-top: 10px;
    padding: 0 0 0 48px;
  }
  .c_view_box {
    width: 100%;
    display: flex;
    justify-content: start;
    padding: 0 0 0 48px;
    .c_view_more {
      width: 100%;
      display: flex;
      justify-content: start;
      align-items: center;
      cursor: pointer;
      .down_icon {
        margin-top: 2px;
        width: 15px;
        height: 13px;
        background: url("../../imgs/gameDetails/down_icon.png") center no-repeat;
        background-size: contain;
      }
      .hit_txt {
        font-size: 14px;
        font-weight: 500;
        color: #9CA0A2;
        margin-left: 8px;
      }
    }
  }

}

.comments_child {
  margin: 8px 0;
  //font-family: Montserrat;
  &:hover {
    .c_header_wrapper {
      .c_header_user {
        .c_c_delete_icon {
          display: block;
        }
      }
      .c_action_wra {
        visibility: visible;
      }
    }
  }
  .c_header_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .c_header_user {
      display: flex;
      align-items: center;
      .c_header_img {
        .c_header_img_Avatar {
          width: 36px;
          height: 36px;
          border: 2px solid #AD4909;
          cursor: pointer;
        }
      }
      .c_user_name {
        max-width: 100px;
        margin-left: 12px;
        font-weight: bold;
        font-size: 16px;
        color: #FFFFFF;
        //line-height: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 4px;
        cursor: pointer;
      }
      .c_time_ago {
        margin-left: 12px;
        font-size: 12px;
        font-weight: 500;
        color: #9CA0A2;
        line-height: 8px;
      }
      .c_c_delete_icon {
        display: none;
        width: 18px;
        height: 18px;
        background: url("../../imgs/delete-icon.png") center no-repeat;
        background-size: contain;
        cursor: pointer;
        margin-left: 5px;
      }
    }
    .c_action_wra {
      visibility: hidden;
      display: flex;
      align-items: flex-start;
      .c_thumbs_wra {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 10px;
        .c_thumbs_icon {
          width: 20px;
          height: 20px;
          //background: url("../../imgs/gamieZone/thumbs-up.png");
          background-size: contain;
          cursor: pointer;
        }
        .c_thumbs_icon_active {
          width: 20px;
          height: 20px;
          background: url("../../imgs/gamieZone/thumbs-up_active.png") center no-repeat;
          background-size: contain;
          cursor: pointer;
        }
        .c_thumbs_num {
          margin-top: 10px;
          font-size: 13px;
          font-weight: 600;
          color: #D0D1D1;
          line-height: 13px;
        }
      }
      .c_rec_wra {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 14px;
        margin-left: 20px;
        .c_rec_icon {
          width: 20px;
          height: 16px;
          background: url("../../imgs/gamieZone/message_icon.png") center no-repeat;
          background-size: contain;
          cursor: pointer;
        }
        .c_rec_icon_active {
          width: 20px;
          height: 16px;
          background: url("../../imgs/gamieZone/thumbs-up_active.png");
          background-size: contain;
          cursor: pointer;
        }
        .c_rec_num {
          margin-top: 10px;
          font-size: 13px;
          font-weight: 600;
          color: #D0D1D1;
          line-height: 13px;
        }
      }
    }
  }
  .c_content_wrapper {
    padding: 0 10px 0 48px;
    font-weight: bold;
    color: #D0D1D1;
    font-size: 14px;
  }
}

.confirm_mobile {
  width: 549px;
  //height: 363px;
  background: #0F1517;
  border-radius: 27px;
  padding: 37px 41px 41px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: Montserrat-Bold, Montserrat;
  .title_wra {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 26px;
      font-weight: bold;
      color: #FFFFFF;
    }
    .close_icon {
      width: 25px;
      height: 25px;
      background: url("../../imgs/igm_modal/close_icon.webp") center no-repeat;
      background-size: contain;
      cursor: pointer;
    }
  }
  .content {
    margin: 40px 0;
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: #FFFFFF;
  }
  .bottom_wra {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .cancel {
      width: 207px;
      height: 62px;
      box-shadow: 0px 29px 21px -22px rgba(199,84,7,0.4);
      border-radius: 16px;
      border: 1px solid #FFFFFF;
      font-size: 18px;
      font-weight: bold;
      color: #D8D8D8;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .sure {
      width: 207px;
      height: 62px;
      background: #C75407;
      box-shadow: 0px 29px 21px -22px rgba(199,84,7,0.4);
      border-radius: 16px;
      border: 1px solid rgba(255,255,255,0.17);
      font-size: 18px;
      font-weight: bold;
      color: #D8D8D8;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.comment_reply_menu {
  width: 129px;
  padding: 8px 0;
  .menu_item {
    width: 129px;
    height: 30px;
    font-size: 15px;
    font-family: PingFangTC-Medium, PingFangTC;
    font-weight: 500;
    color: #FFFFFF;
    line-height:30px;
    cursor: pointer;
    text-align: center;
  }
  .menu_item:hover {
    background: #0F1517;
  }
}

.topic_upload_modal{
  width: 100%;
  color: #FFFFFF;
  background: rgb(2, 39, 53);
  opacity: 0.92;
  border: 2px solid rgba(19,64,70,0.45);
  border-radius: 10px;
  border-bottom-right-radius: 20px;
  .modal_title_block{
    font-size: 28px;
    padding:10px  37px;
    display: flex;
    font-weight: bold;
    justify-content: space-between;
    background: #001119;
    .close_button{
      font-size: 21px;
      line-height: 44px;
    }
  }
  .modal_body{
    padding:21px 37px 12px 37px;
    color: #FFFFFF;
    .search_input{
      display: flex;
      color: #FFFFFF;
      align-items: center;
      .input{
        line-height:50px;
        height: 50px;
        font-size: 18px;
        border: 1px solid rgba(255, 255, 255, 0.17);
        border-radius: 8px;
      }
      .sort_follower{
        margin-left: 25px;
        align-self: center;
        font-size: 16px;
        color: #7C878D;
        .up_icon{
          height: 10px;
          cursor: pointer;
        }
        .down_icon{
          height: 16px;
          cursor: pointer;
        }
        .check_active{
          color: #FFFFFF;
        }
      }
    }
    .search_topic_input{
      display: flex;
      color: #FFFFFF;
      align-items: center;
      .input_wrap{
        width: 320px;
        .input{
          line-height:50px;
          height: 50px;
          font-size: 18px;
          border: 1px solid rgba(255, 255, 255, 0.17);
          border-radius: 8px;
        }
      }
      .sort_follower{
        margin-left: 10px;
        align-self: center;
        .up_icon{
          height: 10px;
          cursor: pointer;
        }
        .down_icon{
          height: 14px;
          color: #7C878D;
          cursor: pointer;
        }
      }
      .create_topic{
        background: #D0A62C;
        border-radius: 8px;
        padding: 12px 19px 16px 18px;
        height: 52px;
        font-size: 18px;
        font-weight: bold;
        cursor: pointer;
        margin-left: 18px;
      }
    }
    .topics_map{
      display: flex;
      justify-content: space-between;
      height: 44px;
      margin-top: 12px;
      line-height: 44px;
      padding: 0 14px;
      font-weight: 500;
      background: #18343F;
      cursor: pointer;
      font-size: 16px;
      border-radius: 5px;
    }
  }

  .alert_wrap{
    color: #FFFFFF;
    box-shadow: 0px 30px 24px 0px #011118;
    .title{
      display: flex;
      width: 100%;
      justify-content: flex-end;
      .close_button{
        font-size: 21px;
        margin-top:18px;
        margin-right: 18px;
      }
    }
    .content{
      width: 100%;
      line-height: 170px;
      text-align: center;
      font-size: 22px;
    }
    .bottom_button{
      display: flex;
      justify-content: space-between;
      border-top: 1px solid rgba(255, 255, 255, 0.12);
      height: 70px;
      line-height: 70px;
      text-align: center;
      .cancel_button{
        width: 50%;
        color: #8A9398;
        font-weight: bold;
        font-size: 18px;
        border-right: 1px solid rgba(255, 255, 255, 0.12);
        cursor: pointer;
      }
      .cancel_button:hover{
        color: #FFFFFF;
      }
      .confirm_button{
        width: 50%;
        color: #FFFFFF;
        font-weight: bold;
        font-size: 18px;
        cursor: pointer;
      }
    }
  }

  .modal_body_wrap{
    padding:10px 37px 20px 37px;
    color: #FFFFFF;
    overflow-y: scroll;
    height: 590px;
    margin-bottom: 10px;
    .user_item{
      display: flex;
      justify-content: space-between;
      align-self: center;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      padding: 17px 0 16px 0;
      .user_item_head_name{
        display: flex;
        .img_head_pic{
          width: 60px;
          height: 60px;
          border-radius: 30px;
          object-fit: cover;
          cursor: pointer;
        }
        .user_item_username{
          font-size: 22px;
          margin-left: 17px;
          font-weight: 500;
          cursor: pointer;
        }
        .user_item_info{
          font-size: 16px;
          color: #8A9398;
          margin-left: 17px;
        }
        .user_item_topic_name{
          font-size: 22px;
          font-weight: 500;
          cursor: pointer;
        }
        .user_item_topic_info{
          font-size: 16px;
          color: #8A9398;
        }
      }
      .user_item_follow_button{
        align-self: center;
        font-size: 16px;
        border: 1px solid #D0A62C;
        width: 100px;
        height: 45px;
        line-height: 45px;
        text-align: center;
        border-radius: 4px;
        cursor: pointer;
        color: #D0A62C;
      }
      .user_item_following_button{
        align-self: center;
        font-size: 16px;
        border: 1px solid #7C878D;
        width: 100px;
        height: 45px;
        line-height: 45px;
        text-align: center;
        border-radius: 4px;
        cursor: pointer;
        color: #7C878D;
      }
    }
    .user_item_1{
      padding: 0 0 16px 0;
    }
  }
  .scroll_bar::-webkit-scrollbar {/*滚动条整体样式*/
    width: 9px !important;     /*高宽分别对应横竖滚动条的尺寸*/
    background: #041822 !important;
    display: block;
  }
  .scroll_bar::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    border-radius: 7px!important;
    width: 9px!important;
    -webkit-box-shadow: inset 0 0 5px #203A46 !important;
    background: #203A46!important;
  }
  .scroll_bar::-webkit-scrollbar-track {/*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px #041822 !important;
    border-radius: 10px!important;
    background: #041822 !important;
  }

}

.reward_modal_page {
  background: #0F1517;
  padding: 10px 22px 20px;
  border-radius: 8px;
  .header_wrapper {
    margin-bottom: 22px;
    .title {
      //margin-left: 14px;
      font-weight: bold;
      font-size: 16px;
      color: #FFFFFF;
    }
  }

  .user_info_wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 22px;
    .head_pic {
      .header_img_Avatar {
        width: 46px;
        height: 46px;
        border: 2px solid #F488B9;
        cursor: pointer;
      }
    }
    .user_name {
      margin-left: 14px;
      font-weight: bold;
      font-size: 18px;
      color: #FFFFFF;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
    }
  }

  .input_wra {
    background-color: #132024 !important;
    width: 100%;
    height: 45px;
    line-height: 45px;
    border: unset;
    border-radius: 8px;
    //font-family: Montserrat-SemiBold, Montserrat;
    font-size: 15px;
    font-weight: 600;
    :global {
      .ant-input-number-handler-wrap {
        display: none;
      }
      .ant-input-number-input {

      }
      .ant-input-number-input::-webkit-input-placeholder {
        font-weight: 500;
        color: #9A9EA0;
        font-size: 16px;
        //font-family: Montserrat;
      }
      .ant-input-number-input::-moz-placeholder {
        font-weight: 500;
        color: #9A9EA0;
        font-size: 16px;
        //font-family: Montserrat;
      }
      .ant-input-number-input::-ms-input-placeholder {
        font-weight: 500;
        color: #9A9EA0;
        font-size: 16px;
        //font-family: Montserrat;
      }
    }
  }

  .text_area {
    //min-height: 50px;
    //line-height: 45px;
    //background-color: #132024;
    line-height: 19px;
    border-radius: 8px;
    //overflow: hidden;
    border: 0;
    //padding: 18px 41px 10px 21px;
    //padding-top: 10px;
    //padding-bottom: 10px;
    font-weight: 500;
    color: #FFFFFF;
    font-size: 16px;
    &::after {
      color: rgb(255, 255, 255)
    }
    :global {
      .ant-input {
        min-height: 50px;
        background-color: #132024;
        border: unset;
        border-radius: 8px;
        font-weight: 500;
        color: #FFFFFF;
        font-size: 16px;
        //line-height: 19px;
      }
      .ant-input::-webkit-input-placeholder {
        font-weight: 500;
        color: #9A9EA0;
        font-size: 16px;
        //font-family: Montserrat;
      }
      .ant-input::-moz-placeholder {
        font-weight: 500;
        color: #9A9EA0;
        font-size: 16px;
        //font-family: Montserrat;
      }
      .ant-input::-ms-input-placeholder {
        font-weight: 500;
        color: #9A9EA0;
        font-size: 16px;
        //font-family: Montserrat;
      }
    }
    //background: rgba(0, 0, 0, 0) !important;
  }

  .btn_wrapper {
    margin-top: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .cancel_btn {
      width: 130px;
      height: 40px;
      border-radius: 8px;
      color: #F488B9;
      border: 1px solid #F488B9;
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
      cursor: pointer;
      user-select: none;
    }
    .send_btn {
      width: 130px;
      height: 40px;
      border-radius: 8px;
      color: #590B57;
      border: unset;
      background-color: #F488B9;
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.zone_page {
  width: 843px;
  height: 716px;
  background: #0F1517;
  border-radius: 27px;
  border: 1px solid #132024;
  position: relative;
  overflow: hidden;
  display: flex;
  font-family: 'Montserrat';
  .left_wrapper {
    width: 50%;
    height: 100%;
    .skeletion_box {
      width: 100%;
      height: 100%;
    }
    .item_video {
      width: 100%;
      height: 100%;
    }
    .nft_img_bg {
      width: 100%;
      height: 100%;
      background-size: cover;
      .nft_img_bg_filter {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.3);
        backdrop-filter: blur(30px);
        -webkit-backdrop-filter: blur(30px);
        border-radius: 27px 0 0 27px;
        overflow: hidden;
        .img_box_wrapper {
          padding: 0 44px;
          width: 100%;
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .carousel_left_btn {
            position: absolute;
            left: 0;
            background: url("../../imgs/discover/arrow-right-bold.png") center no-repeat;
            background-size: contain;
            transform: rotate(180deg);
            width: 40px;
            height: 40px;
            cursor: pointer;
            transition: all .3s;
            &:hover {
              width: 50px;
              height: 50px;
            }
          }
          .carousel_right_btn {
            position: absolute;
            right: 0;
            background: url("../../imgs/discover/arrow-right-bold.png") center no-repeat;
            background-size: contain;
            width: 40px;
            height: 40px;
            cursor: pointer;
            transition: all .3s;
            &:hover {
              width: 50px;
              height: 50px;
            }
          }
          .img_box {
            width: 100%;
            border-radius: 27px !important;
            overflow: hidden;
            //height: auto !important;
            //overflow: hidden;
            .img {
              width: 100%;
              height: auto;
              border-radius: 27px;
              overflow: hidden;
            }
          }
          .week_best{
            background: url("../../imgs/screent-label-weekbest.png") no-repeat;
            position: absolute;
            background-size: cover;
            width: 100px;
            height: 30px;
            top:15px;
            left:-7px;
            color: #fff;
            text-align: center;
            font-weight: bold;
            line-height: 25px;
            font-size: 12px;
          }
          .hot_deal{
            background: url("../../imgs/screen-label-hotdeal.png") no-repeat;
            position: absolute;
            background-size: cover;
            width: 100px;
            height: 30px;
            top:15px;
            left:-7px;
            font-weight: bold;
            color: #fff;
            text-align: center;
            line-height: 25px;
            font-size: 12px;
          }
        }
        .abbreviated_img_wrapper {
          width: auto;
          max-width: 100%;
          height: 100px;
          display: -webkit-box;
          overflow: hidden;
          overflow-x: scroll;
          flex-wrap: nowrap;
          margin-top: 15px;
          .abbreviated_item {
            width: 80px;
            height: 80px;
            position: relative;
            margin: 0 5px;
            border-radius: 4px;
            overflow: hidden;
            cursor: pointer;
            .abbreviated_img {
              width: 100%;
              height: 100%;
              img {
                width: 80px;
                height: 80px;
                object-fit: contain;
              }
            }
            .abbreviated_mask {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background-color: rgba(0, 0, 0, .7);
              opacity: 1;
              border-radius: 4px;
              transition: all .3s;
            }
            .abbreviated_mask_none {
              opacity: 0;
            }
          }
          //background: red;
        }
      }
    }
  }
  .right_wrapper {
    width: 50%;
    height: 100%;
    padding: 25px 0;
    overflow: auto;
    .mask_wrapper {
      width: 100%;
      height: 0;
      background: #0F1517;
      border-radius: 0 27px 0 0;
    }
    .header_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #0F1517;
      border-radius: 0 27px 0 0;
      padding: 10px 25px;
      .header_user {
        display: flex;
        align-items: center;
        .header_img {
          .header_img_Avatar {
            width: 46px;
            height: 46px;
            border: 2px solid #AD4909;
            cursor: pointer;
          }
        }
        .user_name {
          margin-left: 14px;
          width: 120px;
          font-weight: bold;
          font-size: 18px;
          color: #FFFFFF;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          cursor: pointer;
        }
      }
      .action_wra {
        display: flex;
        align-items: center;
        .follow_button{
          cursor: pointer;
          -moz-user-select:none;
          -webkit-user-select:none;
          user-select:none;
          margin-right: 8px;
          border: 1px solid #F488B9;
          min-width: 79px;
          padding: 0 5px;
          line-height: 26px;
          font-size: 14px;
          border-radius: 4px;
          color: #FFFFFF;
          height: 27px;
          text-align: center;
        }
        .friends_button{
          border: 1px solid #7C878D;
          color: #7C878D;
        }
        .share_wra {
          display: flex;
          align-items: center;
          cursor: pointer;
          //margin-right: 8px;
          .share_icon {
            width: 27px;
            height: 27px;
            background: url("../../imgs/gamieZone/share_icon.png") center no-repeat;
            background-size: contain;
          }
          .share_num {
            margin-left: 9px;
            font-weight: 400;
            color: #BEBCC2;
            font-family: "PingFangSC-Regular", "PingFang SC";
            font-size: 16px;
          }
        }
        .action_menu_icon{
          right: 10px;
          background: url("../../imgs/menuMore.svg") right no-repeat;
          width:30px;
          height: 30px;
          transition: all 0.3s;
          background-size: contain;
          cursor: pointer;
        }
      }
    }
    .header_wrapper_border {
      border-bottom: 1px solid #3A3E3F;
    }
    .content_wrapper {
      padding: 15px 25px 0 25px;
      .title {
        line-height: 19px;
        font-size: 17px;
        font-weight: 700;
        color: #FFFFFF;
      }
      .tag_wrapper {
        margin: 10px 0;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .tag_item {
          width: auto;
          padding: 1px 10px;
          background: rgb(205, 73, 39);
          color: #FFFFFF;
          border-radius: 5px;
          font-weight: 500;
          margin-bottom: 7px;
          margin-right: 10px;
          cursor: pointer;
        }
      }
      .content {
        line-height: 19px;
        font-size: 16px;
        font-weight: 600;
        color: #FFFFFF;
      }
      .time_ago {
        margin-top: 25px;
        font-size: 13px;
        font-weight: 600;
        color: #9CA0A2;
      }
    }
    .nft_sole_wrapper {
      display: flex;
      margin-top: 26px;
      justify-content: space-between;
      align-items: center;
      padding: 0 25px;
      .igm_wra {
        display: flex;
        align-items: center;
        .igm_icon {
          width: 25px;
          height: 25px;
          background: url("../../imgs/igm_modal/igm_icon.webp") center no-repeat;
          background-size: contain;
        }
        .igm_num {
          margin-left: 5px;
          font-size: 18px;
          font-weight: bold;
          color: #F7B500;
        }
      }
      .network_wra {
        display: flex;
        align-items: center;
        .btp_icon {
          width: 25px;
          height: 25px;
          background: url("../../imgs/igm_withdrawal/bep_icon.png") center no-repeat;
          background-size: contain;
        }
        .eth_icon {
          width: 25px;
          height: 25px;
          background: url("../../imgs/igm_withdrawal/eth_icon.png") center no-repeat;
          background-size: contain;
        }
        .text {
          margin-left: 5px;
          font-size: 18px;
          font-weight: bold;
          color: #FFFFFF;
        }
      }
    }
    .zone_content {
      display: none;
    }
    .driver_line {
      margin: 27px 25px;
      height: 1px;
      opacity: 0.28;
      border: 1px solid #979797;
    }
    .comments_wrapper {
      width: 100%;
      padding: 0 25px 10px;
      margin-bottom: 80px;
      .title_wra {
        margin-bottom: 21px;
        .title {
          font-size: 20px;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 24px;
        }
        .sub_title {
          font-size: 16px;
          font-weight: 500;
          color: #9A9EA0;
          line-height: 22px;
        }
      }
      .emo_wra {
        background: #0F1517;
        box-sizing: border-box;
        width: 100%;
        display: flex;
        overflow-x:scroll;
        white-space:nowrap;
      }
      .input_wra {
        position: relative;
        width: 100%;
        min-height: 54px;
        overflow: hidden;
        background: #0F1517;
        .input {
          min-height: 54px;
          //line-height: 45px;
          line-height: 19px;
          border-radius: 12px;
          border: 0;
          padding: 18px 41px 10px 21px;
          font-weight: 500;
          color: #FFFFFF;
          font-size: 16px;
          background: #000000 !important;
        }
        .input::-webkit-input-placeholder {
          font-weight: 500;
          color: #9A9EA0;
          font-size: 16px;
          //font-family: Montserrat;
        }
        .input::-moz-placeholder {
          font-weight: 500;
          color: #9A9EA0;
          font-size: 16px;
          //font-family: Montserrat;
        }
        .input::-ms-input-placeholder {
          font-weight: 500;
          color: #9A9EA0;
          font-size: 16px;
          //font-family: Montserrat;
        }
        .send_icon {
          position: absolute;
          right: 17px;
          bottom: 17px;
          width: 17px;
          height: 17px;
          background: url("../../imgs/gamieZone/send_icon.png") center no-repeat;
          background-size: contain;
          cursor: pointer;
        }
        .input_close_icon {
          display: none;
        }
        .send_loading {
          position: absolute;
          right: 20px;
          bottom: 25px;
          width: 17px;
          height: 17px;
          //background: url("../../imgs/gamieZone/send_icon.png") center no-repeat;
          //background-size: contain;
          cursor: pointer;
          .loading_icon {
            width: 17px;
            height: 17px;
          }
        }
      }
      .comment_content_wra {
        width: 100%;
        .view_more {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          .down_icon {
            margin-top: 2px;
            width: 15px;
            height: 13px;
            background: url("../../imgs/gameDetails/down_icon.png") center no-repeat;
            background-size: contain;
          }
          .hit_txt {
            font-size: 14px;
            font-weight: 500;
            color: #9CA0A2;
            margin-left: 8px;
          }
        }
      }
      .comment_loading_wra {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .game_wrapper {
      padding: 0px 25px;
      margin-bottom: 80px;
      .title {
        font-size: 19px;
        font-weight: 600;
        color: #FFFFFF;
        margin-bottom: 5px;
      }
      .game_box {
        display: flex;
        width: 100%;
        height: 90px;
        cursor: pointer;
        .img_box {
          width: 140px;
          height: 100%;
          border-radius: 7px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .game_content {
          width: 220px;
          margin-left: 10px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .game_name {
            overflow:hidden;
            text-overflow:ellipsis;
            white-space:nowrap;
            font-size: 15px;
            color: #FFFFFF;
            font-weight: 600;
            line-height: 18px;
          }
          .game_detail_box {
            padding: 5px 10px;
            position: relative;
            background: #3A3E3F;
            height: 60px;
            border-radius: 7px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .game_detail_sku_name {
              font-size: 13px;
              font-weight: 600;
              color: #FFFFFF;
            }
            .game_detail_sku_price {
              font-weight: 600;
            }
            .free_sale_tag {
              position: absolute;
              right: 0px;
              font-size: 12px;
              width: 93px;
              height: 22px;
              line-height: 22px;
              padding-left: 5px;
              top: 0px;
              background: url('../../imgs/tag_sale.png') center no-repeat;
              background-size: contain;
              color: #fff;
              text-align: center;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
  .input_mask {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    //background: rgba(0, 0, 0, 0.5);
  }
  .bottom_wrapper {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50%;
    height: 93px;
    padding: 10px 25px;
    background: #0F1517;
    border-top: 1px solid #3A3E3F;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .action_wra {
      display: flex;
      .action_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 9px;
        margin-right: 30px;
        .view_icon {
          width: 32px;
          height: 32px;
          background: url("../../imgs/gamieZone/view_icon.png") center no-repeat;
          background-size: contain;
          cursor: pointer;
        }
        .like_icon {
          width: 32px;
          height: 32px;
          background: url("../../imgs/gamieZone/like_icon.png") center no-repeat;
          background-size: contain;
          cursor: pointer;
        }
        .like_icon_active {
          width: 32px;
          height: 32px;
          background: url("../../imgs/gamieZone/like_icon_active.png") center no-repeat;
          background-size: contain;
          cursor: pointer;
        }
        .num {
          margin-top: 7px;
          font-size: 13px;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 16px;
        }
        .download_icon {
          background: url("../../imgs/generate/download_icon.png") center no-repeat;
          background-size: contain;
          width: 40px;
          height: 40px;
        }
      }

    }
    .make_wra {
      display: flex;
      .send_message_icon {
        display: none;
      }
      .delete_button {
        width: 172px;
        height: 60px;
        background: linear-gradient(360deg, #A98216 0%, #DBAD2A 100%);
        border-radius: 16px;
        border: 1px solid rgba(255,255,255,0.17);
        font-weight: bold;
        color: #FFFFFF;
        font-size: 18px;
        line-height: 60px;
        text-align: center;
      }
      .make_button {
        width: 172px;
        height: 60px;
        background: #C75407;
        border-radius: 16px;
        border: 1px solid rgba(255,255,255,0.17);
        text-align: center;
        line-height: 60px;
        font-weight: bold;
        color: #D8D8D8;
        font-size: 18px;
        cursor: pointer;
      }
      .reward_button {
        width: 162px;
        height: 45px;
        background: #F488B9;
        border-radius: 16px;
        border: 1px solid rgba(255,255,255,0.17);
        text-align: center;
        line-height: 45px;
        font-weight: bold;
        color: #590B57;
        font-size: 18px;
        cursor: pointer;
        user-select: none;
      }
    }
    .bottom_input_wra {
      display: none;
    }
  }
  .close_icon {
    position: absolute;
    top: 25px;
    left: 33px;
    width: 23px;
    height: 23px;
    background: url("../../imgs/igm_modal/close_icon.webp") center no-repeat;
    background-size: contain;
    cursor: pointer;
  }
}


@media only screen and (max-width: 640px){

  .confirm_mobile {
    width: 100%;
    height: unset;
    background: #0F1517;
    border-radius: .27rem;
    padding: .36rem .36rem .5rem;
    font-family: Montserrat-Bold, Montserrat;
    .title_wra {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        font-size: .32rem;
        font-weight: bold;
        color: #FFFFFF;
      }
      .close_icon {
        width: .32rem;
        height: .32rem;
        background: url("../../imgs/igm_modal/close_icon.webp") center no-repeat;
        background-size: contain;
        cursor: pointer;
      }
    }
    .content {
      margin: .4rem 0 .5rem;
      width: 100%;
      text-align: center;
      font-size: .26rem;
      font-weight: 570;
      color: #FFFFFF;
      height: 1.1rem;
      display: flex;
      align-items: center;
    }
    .bottom_wra {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .cancel {
        width: 2.3rem;
        height: .8rem;
        box-shadow: 0px 29px 21px -22px rgba(199,84,7,0.4);
        border-radius: .16rem;
        border: .5px solid #FFFFFF;
        font-size: .22rem;
        font-weight: bold;
        color: #D8D8D8;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      .sure {
        width: 2.3rem;
        height: .8rem;
        background: #C75407;
        box-shadow: 0px 29px 21px -22px rgba(199,84,7,0.4);
        border-radius: .16rem;
        border: 1px solid rgba(255,255,255,0.17);
        font-size: .22rem;
        font-weight: bold;
        color: #D8D8D8;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .reward_modal_page {
    padding: .35rem .32rem .32rem;
    .header_wrapper {
      margin-bottom: .22rem;
    }
    .user_info_wrapper {
      margin-bottom: .22rem;
    }
    .input_wra {
      height: .8rem;
      line-height: .8rem;
      font-size: .24rem;
      :global {
        .ant-input-number-input::-webkit-input-placeholder {
          font-size: .24rem;
        }
        .ant-input-number-input::-moz-placeholder {
          font-size: .24rem;
        }
        .ant-input-number-input::-ms-input-placeholder {
          font-size: .24rem;
        }
      }
    }
    .text_area {
      font-size: .24rem;
      :global {
        .ant-input {
          min-height: 1.3rem;
          font-size: .24rem;
        }
        .ant-input::-webkit-input-placeholder {
          font-size: .24rem;
        }
        .ant-input::-moz-placeholder {
          font-size: .24rem;
        }
        .ant-input::-ms-input-placeholder {
          font-size: .24rem;
        }
      }
    }
    .btn_wrapper {
      margin-top: .92rem;
      .cancel_btn {
        width: 2rem;
        height: .7rem;
        font-size: .24rem;
      }
      .send_btn {
        width: 2rem;
        height: .7rem;
        font-size: .24rem;
      }
    }
  }

  .comments_parents {
    margin: unset;
    //font-family: Montserrat;
    .c_header_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .c_header_user {
        display: flex;
        align-items: center;
        .c_header_img {
          .c_header_img_Avatar {
            width: .53rem;
            height: .53rem;
            border: 2px solid #AD4909;
            cursor: pointer;
          }
        }
        .c_user_name {
          max-width: 100px;
          margin-left: .27rem;
          font-weight: bold;
          font-size: .26rem;
          color: #FFFFFF;
          //line-height: 12px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          cursor: pointer;
        }
        .c_time_ago {
          margin-left: 12px;
          font-size: .2rem;
          font-weight: 500;
          color: #9CA0A2;
          line-height: 8px;
        }
      }
      .c_action_wra {
        display: flex;
        align-items: flex-start;
        height: 40px;
        .c_thumbs_wra {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 10px;
          .c_thumbs_icon {
            width: 20px;
            height: 20px;
            background: url("../../imgs/gamieZone/thumbs-up.png") center no-repeat;
            background-size: contain;
            cursor: pointer;
          }
          .c_thumbs_icon_active {
            width: 20px;
            height: 20px;
            background: url("../../imgs/gamieZone/thumbs-up_active.png") center no-repeat;
            background-size: contain;
            cursor: pointer;
          }
          .c_thumbs_num {
            margin-top: 10px;
            font-size: 13px;
            font-weight: 600;
            color: #D0D1D1;
            line-height: 13px;
          }
        }
        .c_rec_wra {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 13px;
          margin-left: 20px;
          .c_rec_icon {
            width: 30px;
            height: 17px;
            background: url("../../imgs/gamieZone/message_icon.png") center no-repeat;
            background-size: contain;
            cursor: pointer;
          }
          .c_rec_icon_active {
            width: 30px;
            height: 17px;
            background: url("../../imgs/gamieZone/message_icon_active.png") center no-repeat;
            background-size: contain;
            cursor: pointer;
          }
          .c_rec_num {
            margin-top: 10px;
            font-size: 13px;
            font-weight: 600;
            color: #D0D1D1;
            line-height: 13px;
          }
        }
      }
    }
    .c_content_wrapper {
      padding: 0 0 0 .8rem;
      //width: 100px;
      display: block;
      word-break: break-all;
      font-weight: bold;
      color: #D0D1D1;
      font-size: 14px;
    }
    .c_child {
      margin-top: .2rem;
      padding: unset;
    }
    .c_view_box {
      width: 100%;
      display: flex;
      justify-content: start;
      padding: .25rem 0 .25rem .8rem;
      .c_view_more {
        width: 100%;
        display: flex;
        justify-content: start;
        align-items: center;
        cursor: pointer;
        .down_icon {
          margin-top: 2px;
          width: 15px;
          height: 13px;
          background: url("../../imgs/gameDetails/down_icon.png") center no-repeat;
          background-size: contain;
        }
        .hit_txt {
          font-size: 14px;
          font-weight: 500;
          color: #9CA0A2;
          margin-left: 8px;
        }
      }
    }

  }
  .comments_child {
    margin: unset;
    padding: 0 0 0 .8rem;
    //font-family: Montserrat;
    &:hover {
      .c_header_wrapper {
        .c_action_wra {
          visibility: visible;
        }
      }
    }
    .c_header_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .c_header_user {
        display: flex;
        align-items: center;
        .c_header_img {
          .c_header_img_Avatar {
            width: .53rem;
            height: .53rem;
            border: 2px solid #AD4909;
            cursor: pointer;
          }
        }
        .c_user_name {
          max-width: 100px;
          margin-left: .27rem;
          font-weight: bold;
          font-size: .26rem;
          color: #FFFFFF;
          //line-height: 12px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-bottom: 4px;
          cursor: pointer;
        }
        .c_time_ago {
          margin-left: 12px;
          font-size: .2rem;
          font-weight: 500;
          color: #9CA0A2;
          line-height: 8px;
        }
      }
      .c_action_wra {
        visibility: hidden;
        display: flex;
        align-items: flex-start;
        .c_thumbs_wra {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 10px;
          .c_thumbs_icon {
            width: 20px;
            height: 20px;
            //background: url("../../imgs/gamieZone/thumbs-up.png");
            background-size: contain;
            cursor: pointer;
          }
          .c_thumbs_icon_active {
            width: 20px;
            height: 20px;
            background: url("../../imgs/gamieZone/thumbs-up_active.png") center no-repeat;
            background-size: contain;
            cursor: pointer;
          }
          .c_thumbs_num {
            margin-top: 10px;
            font-size: 13px;
            font-weight: 600;
            color: #D0D1D1;
            line-height: 13px;
          }
        }
        .c_rec_wra {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 13px;
          margin-left: 20px;
          .c_rec_icon {
            width: 30px;
            height: 17px;
            background: url("../../imgs/gamieZone/message_icon.png") center no-repeat;
            background-size: contain;
            cursor: pointer;
          }
          .c_rec_icon_active {
            width: 20px;
            height: 17px;
            background: url("../../imgs/gamieZone/thumbs-up_active.png");
            background-size: contain;
            cursor: pointer;
          }
          .c_rec_num {
            margin-top: 10px;
            font-size: 13px;
            font-weight: 600;
            color: #D0D1D1;
            line-height: 13px;
          }
        }
      }
    }
    .c_content_wrapper {
      padding: 0 0 0 .8rem;
      font-weight: bold;
      color: #D0D1D1;
      font-size: 14px;
    }
  }
  .zone_page {
    width: 100%;
    height: unset;
    border: none;
    border-radius: 0;
    background: #000000;
    display: flex;
    flex-direction: column;
    .left_wrapper {
      display: none;
    }
    .right_wrapper {
      width: 100%;
      overflow: unset;
      display: flex;
      flex-direction: column;
      .driver_line {
        margin: .29rem .27rem;
        padding: unset;
        height: .5px;
        opacity: 0.28;
        border: .5px solid #979797;
      }
      .header_wrapper {
        background: #000000;
        margin: unset;
        padding: 0 .27rem;
        .header_user {
          .header_img {
            .header_img_Avatar {
              width: .87rem;
              height: .87rem;
            }
          }
          .user_name {
            width: 2.1rem;
            font-size: .24rem;
          }
        }
        .action_wra {
          .follow_button {
            min-width: 1.3rem;
            height: .5rem;
            font-size: .24rem;
            line-height: .5rem;
          }
          .action_menu_icon {
            background: url("../../imgs/menuMore.svg") center no-repeat;
          }
        }
      }
      .content_wrapper {
        padding: 0 .27rem;
        .tag_wrapper {
          margin: unset;
          .tag_item {
            padding: .05rem .28rem;
            border-radius: .23rem;
            background: #C75407;
            font-size: .26rem;
            margin-right: .39rem;
            margin-bottom: .3rem;
            margin-top: .3rem;
          }
        }
        .content {
          font-size: .26rem;
          font-weight: 570;
          margin-top: 0rem;
        }
        .time_ago {
          margin-top: .27rem;
          margin-bottom: .38rem;
          font-size: .20rem;
          font-weight: 570;
        }
      }
      .nft_sole_wrapper {
        padding: 0 .27rem;
        margin: unset;
        margin-bottom: .27rem;
        .network_wra {
          .text {
            font-size: .3rem;
            font-weight: 600;
          }
        }
      }
      .zone_content {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 .27rem;
        .skeletion_box {
          border-radius: .23rem;
          width: 100%;
          min-height: 3.5rem;
        }
        .item_video {
          width: 100%;
          height: 100%;
        }
        .nft_img_bg {
          width: 100%;
          height: 100%;
          background-size: cover;
          .nft_img_bg_filter {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .img_box_wrapper {
              width: 92vw;
              position: relative;
              .img_box {
                width: 100%;
                //border-radius: .27rem;
                //overflow: hidden;
                .img {
                  border-radius: .27rem;
                  overflow: hidden;
                  width: 100%;
                  height: auto;
                }
              }
              .week_best{
                background: url("../../imgs/screent-label-weekbest.png") no-repeat;
                position: absolute;
                background-size: cover;
                width: 2.2rem;
                height: .5rem;
                top:15px;
                left:-7px;
                color: #fff;
                text-align: center;
                font-weight: bold;
                line-height: .5rem;
                font-size: .23rem;
              }
              .hot_deal{
                background: url("../../imgs/screen-label-hotdeal.png") no-repeat;
                position: absolute;
                background-size: cover;
                width: 2.2rem;
                height: .5rem;
                top:15px;
                left:-7px;
                font-weight: bold;
                color: #fff;
                text-align: center;
                line-height: .5rem;
                font-size: .23rem;
              }
            }
            .abbreviated_img_wrapper {
              width: 100%;
              height: 1.2rem;
              display: -webkit-box;
              overflow: hidden;
              overflow-x: scroll;
              flex-wrap: nowrap;
              margin-top: .22rem;
              .abbreviated_item {
                width: 1.2rem;
                height: 1.2rem;
                position: relative;
                margin: 0 .11rem;
                border-radius: 4px;
                overflow: hidden;
                cursor: pointer;
                .abbreviated_img {
                  width: 100%;
                  height: 100%;
                  img {
                    width: 1.2rem;
                    height: 1.2rem;
                    object-fit: contain;
                  }
                }
                .abbreviated_mask {
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  background-color: rgba(0, 0, 0, .7);
                  opacity: 1;
                  border-radius: 4px;
                  transition: all .3s;
                }
                .abbreviated_mask_none {
                  opacity: 0;
                }
              }
              //background: red;
            }
          }
        }
      }
      .comments_wrapper {
        padding: 0 .27rem;
        .title_wra {
          .title {
            font-size: .28rem;
          }
          .sub_title {
            display: none;
          }
        }
        .emo_wra {
          display: none;
        }
        .input_wra {
          display: none;
        }
      }
      .game_wrapper {
        width: 100%;
        margin: unset;
        padding: 0 .27rem;
        //background: red;
        .game_box {
          width: 100%;
          .img_box {
            width: 2rem;
            flex: 2;
          }
          .game_content {
            width: 1rem;
            flex: 4;
          }
        }
      }
    }
    .bottom_wrapper {
      width: 100%;
      position: fixed;
      z-index: 9999;
      background: #0F1517;
      border: unset;
      height: unset;
      min-height: 1.3rem;
      padding: unset;
      .action_wra {
        width: 50%;
        margin-top: .1rem;
        .action_item {
          padding: 0 0 0 .27rem;
          margin: 0 .27rem 0 0;
          .view_icon {
            width: .5rem;
            height: .5rem;
          }
          .like_icon {
            width: .5rem;
            height: .5rem;
          }
          .like_icon_active {
            width: .5rem;
            height: .5rem;
          }
          .num {
            margin-top: .07rem;
            font-size: .19rem;
          }
          .download_icon {
            width: .5rem;
            height: .5rem;
          }
        }
      }
      .make_wra {
        width: 50%;
        align-items: center;
        justify-content: flex-end;
        .send_message_icon {
          display: unset;
          width: .8rem;
          height: .8rem;
          background: url("../../imgs/gamieZone/message_like_icon.png") center no-repeat;
          background-size: contain;
          margin-right: .27rem;
        }

        .make_button {
          width: 2.8rem;
          height: .9rem;
          border-radius: .16rem;
          line-height: .9rem;
          border: unset;
          margin-right: .27rem;
          font-weight: bold;
          color: #D8D8D8;
          font-size: .26rem;
        }
        .reward_button {
          width: 2.8rem;
          height: .9rem;
          border-radius: .16rem;
          line-height: .9rem;
          border: unset;
          margin-right: .27rem;
          font-weight: bold;
          color: #590B57;
          font-size: .26rem;
        }
      }
      .bottom_input_wra {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: .2rem 0;
        .emo_wra {
          width: 100%;
          display: flex;
          overflow: auto;
          overflow-x: scroll;
        }
        .input_wra {
          margin-top: .1rem;
          position: relative;
          width: 100%;
          min-height: 54px;
          overflow: hidden;
          background: #0F1517;
          padding: 0 .25rem;
          .input {
            min-height: 54px;
            //line-height: 45px;
            line-height: 19px;
            border-radius: 12px;
            border: 0;
            padding: 18px 41px 10px 21px;
            font-weight: 500;
            color: #FFFFFF;
            font-size: 16px;
            background: #000000 !important;
          }
          .input::-webkit-input-placeholder {
            font-weight: 500;
            color: #9A9EA0;
            font-size: 16px;
            //font-family: Montserrat;
          }
          .input::-moz-placeholder {
            font-weight: 500;
            color: #9A9EA0;
            font-size: 16px;
            //font-family: Montserrat;
          }
          .input::-ms-input-placeholder {
            font-weight: 500;
            color: #9A9EA0;
            font-size: 16px;
            //font-family: Montserrat;
          }
          .send_icon {
            position: absolute;
            right: .37rem;
            bottom: .33rem;
            width: .42rem;
            height: .42rem;
            background: url("../../imgs/gamieZone/send_icon.png") center no-repeat;
            background-size: contain;
            cursor: pointer;
          }
          .input_close_icon {
            position: absolute;
            right: .45rem;
            bottom: .35rem;
            width: .32rem;
            height: .32rem;
            background: url("../../imgs/igm_modal/close_icon.webp") center no-repeat;
            background-size: contain;
          }
          .send_loading {
            position: absolute;
            right: .55rem;
            bottom: .4rem;
            width: .42rem;
            height: .42rem;
            //background: url("../../imgs/gamieZone/send_icon.png") center no-repeat;
            //background-size: contain;
            cursor: pointer;
            .loading_icon {
              width: 17px;
              height: 17px;
            }
          }
        }
      }
    }
    .close_icon {
      display: none;
    }
  }
}
